import http from "@/_helper/api-services.js";
export default {
    name: "redirecionar",
    computed: {
        token() {
            return this.$route.query.token;
        }
    },
    mounted() {
        const token = this.token;
        if (token) {
            this.validarToken(token);
        }
        this.hideElements();
    },
    methods: {
        hideElements() {
            const elementsToHide = ['.bar-top', '.box-header', '.footer'];
            elementsToHide.forEach(selector => {
              const elements = document.querySelectorAll(selector);
              elements.forEach(el => {
                el.style.display = 'none';
              });
            });
          },
        validarToken(token) {
            console.log(token);
            http.get(`/usuarioLogin/redirect`, {}, {
                Authorization: `${token}`
            }).then(
                (response) => {
                    localStorage.clear();
                    const userData = response.data;
                    localStorage.setItem("user", JSON.stringify(userData));
                    window.location.href = "/painel";
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }

                    setTimeout(() => {
                        window.location.href = "https://quantashop.com.br/login";
                    }, 3000);
                }
            );
        }
    }
}