import "./Credenciamento.scss";
import http from "@/_helper/api-services.js";
import validador from "@/_helper/validacoes";
import Compress from "compress.js";
const compress = new Compress();

export default {
    name: "Credenciamento",
    filters: {
        formatCpfCnpj: function (value) {
            if (!value) return '';

            // Verifica se é CPF (11 dígitos) ou CNPJ (14 dígitos)
            if (value.length === 11) {
                return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } else if (value.length === 14) {
                return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
            } else {
                // Se não for nem CPF nem CNPJ, retorna o valor original
                return value;
            }
        },
        formatDate: function (value) {
            if (!value) return '';

            const dateObj = typeof value === 'string' ? new Date(value) : value;

            if (!(dateObj instanceof Date) || isNaN(dateObj)) return '';

            const day = dateObj.getDate().toString().padStart(2, '0');
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            const year = dateObj.getFullYear();
            const hour = dateObj.getHours().toString().padStart(2, '0');
            const min = dateObj.getMinutes().toString().padStart(2, '0');
            const sec = dateObj.getSeconds().toString().padStart(2, '0');

            return `${day}/${month}/${year} ${hour}:${min}:${sec}`;
        },
        formatPhone: function (value) {
            if (!value) return '';

            // Remove caracteres não numéricos
            const cleaned = value.replace(/\D/g, '');

            if (cleaned.length === 10) {
                const formattedPhone = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(6, 10)}`;
                return formattedPhone;
            }

            // Formato específico para telefone no Brasil (11 dígitos)
            if (cleaned.length === 11) {
                const formattedPhone = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7, 11)}`;
                return formattedPhone;
            }
        },
        formatPercent: function (value) {
            if (!value) return '';

            // Formato específico para percentual com % (formato pt-BR)
            const formattedPercent = `${parseFloat(value).toFixed(2).replace('.', ',')}%`;
            return formattedPercent;
        }

    },
    data() {
        return {
            fields: [
                {
                    key: "estabelecimento",
                    label: "Estabelecimento",
                    sortable: true,
                },
                { key: "loginResponsavel", label: "Login", sortable: true },
                {
                    key: "loginPatrocinador",
                    label: "Patrocinador",
                    sortable: true,
                },
                { key: "percentualCashback", label: "Cashback", sortable: true },
                { key: "categoria", label: "Categoria", sortable: true },
                { key: "cidade", label: "Cidade", sortable: true },
                { key: "rua", label: "Rua", sortable: true },
                { key: "numero", label: "Número", sortable: true },
                { key: "bairro", label: "Bairro", sortable: true },
                { key: "telefone", label: "Telefone", sortable: true },
                { key: "cnpj", label: "CNPJ", sortable: true },
                { key: "statusDesc", label: "Status", sortable: true },
                { key: "options", label: "" },
            ],
            history: [],
            search: {
                estabelecimento: String(),
                status: 0,
                quantidade: 50,
                loginResponsavel: String(),
                patrocinador: String(),
                idCategoria: null,
                page: 1,
                ordenacao: null,
                asc: null,
            },
            sortBy: String(),
            sortDesc: true,
            status: [{ value: null, text: "Selecione um status" }],
            param: {
                idCredenciamento: Number(),
                contactado: Boolean(),
            },
            filter: null,
            totalPages: 0,
            perPage: 50,
            pageOptions: [20, 50, 100],
            showIconsToChangePage: false,
            categorias: [{ value: null, text: "Selecione uma categoria" }],
            credenciamentoUpdate: Object(),
            statusCredenciamentoUpdate: Object(),
            cashback: Object(),
            estados: [],
            cidades: [],
            ecossistemas: [],
            imageShow: String(),
            filtro: {                
                nome: String(),
                ativo: true,
            },
            exportFields: {
                Estabelecimento: "estabelecimento",
                Login: "loginResponsavel",
                Patrocinador: "patrocinador",
                Cashback: "percentualCashback",
                Categoria: "categoria",
                Cidade: "cidade",
                Rua: "rua",
                Número: "numero",
                Bairro: "bairro",
                Telefone: "telefone",
                CNPJ: "cnpj",
                Status: "statusDesc",
            },
            exportTable: [],
            credenciamentosList: []
        };
    },
    async beforeMount() {
        this.$loading(true);
        //await this.filtrarCredenciamento();
        await this.getStatus();
        await this.getCategorias();
        await this.getEstados();
        await this.getEcossitemas()     
        this.$loading(false);
    },
    computed: {
        totalPagesToDisplay() {
            if (this.totalPages <= 5) {
                this.showIconsToChangePage = false;
                return this.totalPages;
            } else {
                this.showIconsToChangePage = true;

                let paginasSobrando = this.totalPages - this.search.page;

                if (paginasSobrando == 0) {
                    let retorno = [];
                    for (let i = this.totalPages - 2; i <= this.totalPages; i++)
                        retorno.push(i);
                    return retorno;
                } else if (paginasSobrando == this.totalPages - 1)
                    return [1, 2, 3];
                else
                    return [
                        this.search.page - 1,
                        this.search.page,
                        this.search.page + 1,
                    ];
            }
        },
        statusFiltered() {
            return this.status.filter((s) => s.value > 1 || s.value == null);
        },
    },
    watch: {
        perPage() {
            this.search.page = 1;
            this.filtrarCredenciamento();
        },
        async "search.page"() {
            this.$loading(true);
            await this.filtrarCredenciamento();
            this.$loading(false);
        },
        async "credenciamentoUpdate.idEstado"() {
            if (this.credenciamentoUpdate.idEstado)
                await this.getCidades(this.credenciamentoUpdate.idEstado);
        },
        async sortDesc() {
            this.search.asc = this.$data.sortDesc;
            await this.filtrarCredenciamento();
        },
        async sortBy() {
            switch (this.$data.sortBy) {
                case "estabelecimento":
                    this.search.ordenacao = 0;
                    break;
                case "loginResponsavel":
                    this.search.ordenacao = 1;
                    break;
                case "patrocinador":
                    this.search.ordenacao = 2;
                    break;
                case "categoria":
                    this.search.ordenacao = 3;
                    break;
                case "cidade":
                    this.search.ordenacao = 4;
                    break;
                case "rua":
                    this.search.ordenacao = 5;
                    break;
                case "numero":
                    this.search.ordenacao = 6;
                    break;
                case "bairro":
                    this.search.ordenacao = 7;
                    break;
                case "telefone":
                    this.search.ordenacao = 8;
                    break;
                case "cnpj":
                    this.search.ordenacao = 9;
                    break;
                case "statusDesc":
                    this.search.ordenacao = 10;
                    break;
            }

            await this.filtrarCredenciamento();
        },
    },
    methods: {
        scrollToTop: function () {
            // Rola a página suavemente até o topo
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        handleScroll: function () {
            var btnScrollToTop = document.querySelector('.scroll-to-top');
            if (window.scrollY > 20) {
                btnScrollToTop.style.display = 'block';
            } else {
                btnScrollToTop.style.display = 'none';
            }
        },
        validaLogin() {
            if (
                !validador.loginValido(
                    this.credenciamentoUpdate.loginResponsavel
                )
            ) {
                this.$snotify.error(
                    'Login inválido - Não é possível criar um login com as palavras: "bigcash", "bigcashme", "cashme", "big", "cash", "admin", "admins"'
                );
                return false;
            }

            return true;
        },
        validaTelefoneResponsavel() {
            if (
                !validador.telefoneCompletoValido(
                    this.credenciamentoUpdate.celularContato
                )
            ) {
                this.$snotify.error("Informe o telefone do responsável.");
                return false;
            }

            return true;
        },
        validaTelefoneEstabelecimento() {
            if (
                !validador.telefoneCompletoValido(
                    this.credenciamentoUpdate.telefone
                )
            ) {
                this.$snotify.error("Informe o telefone do estabelecimento.");
                return false;
            }

            return true;
        },
        validaCashback() {
            if (this.credenciamentoUpdate.percentualCashback < 1) {
                this.$snotify.error("Percentual de cashback não pode ser menor que 1%.");
                return false;
            }

            return true;
        },
        validaCep() {
            if (!this.credenciamentoUpdate.cep) {
                this.$snotify.error("Informe o CEP.");
                return false;
            }

            if (!validador.cepValido(this.credenciamentoUpdate.cep)) {
                this.$snotify.error("Informe um CEP válido.");
                return false;
            }
            return true;
        },
        validaCNPJ() {
            if (!validador.cnpjValido(this.credenciamentoUpdate.cnpj)) {
                this.$snotify.error("Informe um CPF/CNPJ válido.");
                return false;
            }

            return true;
        },
        validaEmail() {
            if (
                !validador.emailFormatoValido(this.credenciamentoUpdate.email)
            ) {
                this.$snotify.error(
                    'E-mail inválido - E-mails devem ser terminados em: ".com" ou ".com.br"'
                );
                return false;
            }

            if (
                !validador.emailDominioValido(this.credenciamentoUpdate.email)
            ) {
                this.$snotify.error(
                    'E-mail inválido - Não são permitidos emails contendo: ".gov", ".gov.br", "hotmail.com", "msn.com", "yahoo.com", "yahoo.com.br", "globo.com", "icloud.com"'
                );
                return false;
            }

            return true;
        },
        checkForm() {
            return (
                this.validaLogin() &&
                this.validaTelefoneEstabelecimento() &&
                this.validaTelefoneResponsavel() &&
                this.validaCNPJ() &&
                this.validaCep() &&
                this.validaEmail() &&
                this.validaCashback()
            );
        },
        async getStatus() {
            await http.get("/Credenciamento/GetStatus").then(
                (success) => {
                    Object.keys(success.data).forEach((s) => {
                        this.status.push({
                            text: s,
                            value: success.data[s],
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        async getEstados() {
            await http.get("/Geral/buscaEstado").then((response) => {
                response.data.forEach((element) => {
                    this.estados.push({
                        value: element.idEstado,
                        text: element.nome,
                    });
                });
            });
        },
        async getEcossitemas() {
            await http.get(`/Ecossistema/obterEcossistemas`, this.filtro).then((response) => {
                response.data.forEach((element) => {
                    this.ecossistemas.push({
                        value: element.idEcossistema,
                        text: element.nome,
                    });
                });
            });
        },
        async getCategorias() {
            await http.get("/Geral/ObterCategorias").then(
                (success) => {
                    success.data.forEach((s) => {
                        this.categorias.push({
                            value: s.idCategoria,
                            text: s.nome,
                        });
                    });
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                }
            );
        },
        async modalUpdate(credenciamento) {            
            this.$root.$emit("bv::hide::popover");
            this.credenciamentoUpdate = credenciamento;
            this.$bvModal.show("modal-editar-credenciamento");
            await this.getCidades(credenciamento.idEstado);
        },
        closeModal() {
            this.credenciamentoUpdate = Object();
            this.$bvModal.hide("modal-editar-credenciamento");
        },
        async getCidades(idEstado) {
            this.$loading(true);
            this.cidades = [{ text: "Selecione a cidade", value: null }];
            http.get("/Geral/buscaCidade/" + idEstado)
                .then(
                    (response) => {
                        response.data.forEach((element) => {
                            this.cidades.push({
                                value: element.idCidade,
                                text: element.nome,
                            });
                        });
                        if (
                            this.cidades.filter(
                                (c) =>
                                    c.value ==
                                    this.credenciamentoUpdate.idCidade
                            ).length == 0
                        )
                            this.credenciamentoUpdate.idCidade = null;
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        handleSubmitUpdateForm(event) {
            this.$loading(true);

            if (this.checkForm()) {
                http.update("/Credenciamento", this.credenciamentoUpdate).then(
                    async () => {
                        this.$snotify.success(
                            "Credenciamento atualizado com sucesso."
                        );
                        this.$bvModal.hide("modal-editar-credenciamento");
                        await this.filtrarCredenciamento();
                    },
                    (err) => {
                        if (err.response && err.response.status === 400) {
                            const errorResponse = err.response.data;
                            if (errorResponse && errorResponse.erros && errorResponse.erros.length > 0) {
                                const errorMessage = errorResponse.erros[0].mensagem;
                                this.$snotify.error(errorMessage);
                            } else
                                this.$snotify.error("Erro desconhecido ao processar a solicitação.");
                        } else
                            this.$snotify.error("Erro ao atualizar informações.");
                    }
                );
            }
            this.$loading(false);
        },
        openModalStatus(idCredenciamento) {
            this.statusCredenciamentoUpdate = {
                status: null,
                idCredenciamento: idCredenciamento,
            };
            this.$bvModal.show("modal-atualizar-status");
        },
        openModalCashback(idCredenciamento) {
            this.cashback = {
                idCredenciamento: idCredenciamento,
                percentualCashback: "",
            };
            this.$bvModal.show("modal-atualizar-cashback");
        },
        handleSubmitCashbackUpdate(event) {
            event.preventDefault();

            this.$loading(true);
            if (this.cashback.percentualCashback)
                this.cashback.percentualCashback = parseFloat(
                    this.cashback.percentualCashback
                );

            http.update(
                "/Credenciamento/AlterarPercentualCashback",
                this.cashback
            ).then(
                async () => {
                    this.$snotify.success(
                        "Percentual de cashback atualizado com sucesso."
                    );
                    this.$bvModal.hide("modal-atualizar-cashback");
                    await this.filtrarCredenciamento();
                    this.$loading(false);
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                    this.$loading(false);
                }
            );
        },
        handleSubmitStatusUpdate(event) {
            event.preventDefault();

            this.$loading(true);
            if (this.statusCredenciamentoUpdate.percentualCashback)
                this.statusCredenciamentoUpdate.percentualCashback = parseFloat(
                    this.statusCredenciamentoUpdate.percentualCashback
                );

            http.update(
                "/Credenciamento/UpdateStatus",
                this.statusCredenciamentoUpdate
            ).then(
                async () => {
                    this.$snotify.success(
                        "Credenciamento atualizado com sucesso."
                    );
                    this.$bvModal.hide("modal-atualizar-status");
                    await this.filtrarCredenciamento();
                    this.$loading(false);
                },
                (error) => {
                    for (const erro of http.mensagensErro(error)) {
                        this.$snotify.error(erro);
                    }
                    this.$loading(false);
                }
            );
        },
        async filtrarCredenciamento(event) {
            if (event) {
                event.preventDefault();
                this.$loading(true);
            }

            await http
                .post("/Credenciamento/FiltrarCredenciamentos", this.search)
                .then(
                    (success) => {
                        if (success && success.data) {

                            const credenciamentos = success.data.credenciamentoFiltrado;
                            this.credenciamentosList = credenciamentos;

                            // for (let i = 0; i < credenciamentos.length; i++) {
                            //     const credenciamento = credenciamentos[i];
                            //     const percentualCashback = credenciamento.percentualCashback * 1;
                            //     const percentualFormatado = percentualCashback.toFixed(2) + '%';

                            //     credenciamento.percentualCashback = percentualFormatado;
                            // }


                            this.totalPages = success.data.totalPages;
                            this.history = credenciamentos;
                            this.exportTable = [];
                            for (let i in this.history) {
                                this.exportTable.push(this.history[i]);
                            }
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    if (event) this.$loading(false);
                });
        },
        resendEmail(idCredenciamento) {
            this.$loading(true);
            http.get(`/Credenciamento/ReenviarEmail/${idCredenciamento}`)
                .then(
                    () => {
                        this.$snotify.success("Email enviado com sucesso.");
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => this.$loading(false));
        },
        onSelectFile(evt) {
            let input = this.$refs.fileInput;
            const files = input.files;

            if (files && files[0]) {
                if (files[0].size > 1024 * 1024) {
                    this.$snotify.error(
                        "Logo inválida: Arquivo maior do que o permitido (1MB)"
                    );
                } else {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.imageData = e.target.result;
                    };
                    reader.readAsDataURL(files[0]);
                    this.$emit("input", files[0]);

                    compress
                        .attach("#logo", {
                            size: 1,
                            quality: 0.75,
                        })
                        .then((data) => {
                            this.credenciamentoUpdate.imageBase64 =
                                data[0].data;
                            this.imageShow = data[0].prefix + data[0].data;
                        });
                }
            }
        },
        alterarPagina(pagina) {
            this.search.page = pagina;

            this.scrollToTop()
        },
    },
    mounted: function () {
        window.addEventListener('scroll', this.handleScroll);

        this.filtrarCredenciamento();
    },
    destroyed: function () {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
