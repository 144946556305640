import http from "@/_helper/api-services.js";
import { v2 as cloudinary } from 'cloudinary';

export default {
    name: "PainelCarrossel",
    metaInfo: {
        title: 'Gerenciar carrosséis | Quanta Shop',
    },
    components: {
    },
    data() {
        return {
            carrosseis: [],
            textFilter: '',
            locationFilter: null,
            statusFilter: null,
            colorOptions: [
                { value: 'green-dark-bg', text: 'Fundo verde escuro' },
                { value: 'is_light', text: 'Fundo claro' }
            ],
            locationOptions: [
                { value: null, text: 'Todas as localizações' },
                { value: '1', text: 'Carrossel principal' },
                { value: '2', text: 'Cards entre as categorias em destaque e ofertas do dia' },
                { value: '3', text: 'Carrossel da loja Super Humano' },
                { value: '4', text: 'Carrossel acima dos parceiros locais' },
            ],
            statusOptions: [
                { value: null, text: 'Todos os status' },
                { value: true, text: 'Ativo' },
                { value: false, text: 'Inativo' },
            ],
            viewMode: 'card',
            createForm: {
                texto1: '',
                texto2: '',
                texto3: '',
                posicao: null,
                imagem: null,
                imagemUrl: '',
                corFundo: null,
                link: null,
                textoLink: null,
                ativo: true,
                somenteImagem: false,
                ordemExibicao: 0
            },
            editForm: {
                idCarrossel: null,
                texto1: '',
                texto2: '',
                texto3: '',
                posicao: null,
                imagem: null,
                imagemUrl: '',
                corFundo: null,
                link: null,
                textoLink: null,
                ativo: false,
                somenteImagem: false,
                ordemExibicao: 0
            },
            cloudinary: null,
        };
    },
    created() {
        cloudinary.config({
            cloud_name: 'dryd9bfjj',
            api_key: '187616983397835',
            api_secret: 'UPZevn2wo6UrUEcSgfiiIyTpYd8',
            secure: true
        });
    },
    async beforeMount() {
        await this.obterCarrosseis();
    },
    watch: {
        textFilter(newValue) {
            console.log('textFilter changed:', newValue);
        },
        locationFilter(newValue) {
            console.log('locationFilter changed:', newValue);
        },
        statusFilter(newValue) {
            console.log('statusFilter changed:', newValue);
        }
    },
    computed: {
        filteredCarrosseis() {
            return this.carrosseis.filter(carrossel => {
                const textMatch = this.textFilter === '' ||
                    Object.values(carrossel).some(value =>
                        String(value).toLowerCase().includes(this.textFilter.toLowerCase())
                    );

                const locationMatch = this.locationFilter === null || carrossel.posicao === this.locationFilter;
                const statusMatch = this.statusFilter === null || carrossel.ativo === this.statusFilter;

                return textMatch && locationMatch && statusMatch;
            });
        }
    },
    methods: {
        async obterCarrosseis() {
            this.$loading(true);

            http.get("/v2/carousels/")
                .then(
                    (success) => {
                        if (success.data) {
                            this.carrosseis = success.data.data;
                        }
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(error)) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                });
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        openCreateModal() {
            this.resetCreateForm();
            this.$bvModal.show('create-carousel-modal');
        },
        async inativarCarrossel(idCarrossel) {
            try {
                this.$loading(true);

                const carrossel = this.carrosseis.find(c => c.idCarrossel === idCarrossel);

                if (!carrossel) {
                    throw new Error('Carrossel não encontrado');
                }

                carrossel.ativo = false;

                const response = await http.patch(`/v2/carousels/${idCarrossel}`, carrossel);
                if (response.data && response.data.success) {
                    this.$snotify.success('Carrossel inativado com sucesso');
                    await this.obterCarrosseis();
                } else {
                    throw new Error('Falha ao inativar o carrossel');
                }
            } catch (error) {
                const errorMessages = http.mensagensErro(error);
                const allErrors = [error.message, ...errorMessages].join('. ');
                this.$snotify.error(allErrors);
            } finally {
                this.$loading(false);
            }
        },
        async ativarCarrossel(idCarrossel) {
            try {
                this.$loading(true);

                const carrossel = this.carrosseis.find(c => c.idCarrossel === idCarrossel);

                if (!carrossel) {
                    throw new Error('Carrossel não encontrado');
                }

                carrossel.ativo = true;

                const response = await http.patch(`/v2/carousels/${idCarrossel}`, carrossel);
                if (response.data && response.data.success) {
                    this.$snotify.success('Carrossel ativado com sucesso');
                    await this.obterCarrosseis();
                } else {
                    throw new Error('Falha ao ativar o carrossel');
                }
            } catch (error) {
                const errorMessages = http.mensagensErro(error);
                const allErrors = [error.message, ...errorMessages].join('. ');
                this.$snotify.error(allErrors);
            } finally {
                this.$loading(false);
            }
        },
        async excluirCarrossel(idCarrossel) {
            try {
                this.$loading(true);

                const carrossel = this.carrosseis.find(c => c.idCarrossel === idCarrossel);

                if (!carrossel) {
                    throw new Error('Carrossel não encontrado');
                }

                if (carrossel.imagem) {
                    await this.deleteImage(carrossel.imagem);
                }

                const response = await http.delete(`/v2/carousels/${idCarrossel}`);
                if (response.data && response.data.success) {
                    this.$snotify.success('Carrossel excluído com sucesso');
                    await this.obterCarrosseis();
                } else {
                    throw new Error('Falha ao excluir o carrossel');
                }
            } catch (error) {
                const errorMessages = http.mensagensErro(error);
                const allErrors = [error.message, ...errorMessages].join('. ');
                this.$snotify.error(allErrors);
            } finally {
                this.$loading(false);
            }
        },
        recuperarLocalizacao(posicao) {
            const location = this.locationOptions.find(option => option.value === posicao);
            return location ? location.text : "Desconhecido";
        },
        editCarrossel(id) {
            const carrossel = this.carrosseis.find(c => c.idCarrossel === id);
            if (carrossel) {
                this.editForm = {
                    ...carrossel,
                    imagem: carrossel.imagem,
                    imagemUrl: carrossel.imagem
                };

                this.$bvModal.show('edit-carousel-modal');
            }
        },
        async handleSubmit(bvModalEvent) {
            bvModalEvent.preventDefault();
            if (this.validateForm()) {
                await this.uploadImage();
                await this.updateCarrossel(this.editForm);
                this.$bvModal.hide('edit-carousel-modal');
            }
        },
        validateForm() {
            return true;
        },
        async deleteImage(imageUrl) {
            if (!imageUrl) return;

            try {
                const publicId = 'Quanta Shop/' + imageUrl.split('/').pop().split('.')[0];
                const result = await cloudinary.uploader.destroy(publicId);

                if (result.result === 'ok') {
                    console.log('Image deleted successfully');
                } else {
                    console.warn('Failed to delete image:', result);
                }
            } catch (error) {
                console.error('Error deleting image:', error);
                this.$snotify.error('Erro ao excluir a imagem antiga');
            }
        },
        async uploadImage() {
            // Se não houver nova imagem, manter a atual e sair do método
            if (!this.editForm.imagem || !(this.editForm.imagem instanceof File)) {
                return;
            }

            try {
                if (this.editForm.imagemUrl) {
                    await this.deleteImage(this.editForm.imagemUrl);
                }

                const result = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => resolve(e.target.result);
                    reader.onerror = (e) => reject(e);
                    reader.readAsDataURL(this.editForm.imagem);
                });

                const upload = await cloudinary.uploader.upload(result, {
                    folder: 'Quanta Shop',
                    resource_type: 'image'
                });

                this.editForm.imagemUrl = upload.secure_url;
                this.editForm.imagem = upload.secure_url;
            } catch (error) {
                console.error('Error uploading image:', error);
                this.$snotify.error('Erro ao fazer upload da imagem');
            }
        },
        async updateCarrossel(updatedCarrossel) {
            this.$loading(true);

            try {
                const response = await http.patch(`/v2/carousels/${updatedCarrossel.idCarrossel}`, updatedCarrossel);
                if (response.data && response.data.success) {
                    this.$snotify.success('Carrossel atualizado com sucesso');
                    await this.obterCarrosseis();
                } else {
                    throw new Error('Falha ao atualizar o carrossel');
                }
            } catch (error) {
                const errorMessages = http.mensagensErro(error);
                const allErrors = [error.message, ...errorMessages].join('. ');
                this.$snotify.error(allErrors);
            } finally {
                this.$loading(false);
            }
        },
        resetForm() {
            this.editForm = {
                idCarrossel: null,
                texto1: '',
                texto2: '',
                texto3: '',
                posicao: null,
                imagem: null,
                ativo: false
            };
        },
        resetCreateForm() {
            this.createForm = {
                texto1: '',
                texto2: '',
                texto3: '',
                posicao: null,
                imagem: null,
                imagemUrl: '',
                ativo: true
            };
        },
        async handleCreateSubmit(bvModalEvent) {
            bvModalEvent.preventDefault();
            if (this.validateCreateForm()) {
                await this.uploadCreateImage();
                await this.createCarrossel();
                this.$bvModal.hide('create-carousel-modal');
            }
        },
        async uploadCreateImage() {
            if (this.createForm.imagem && this.createForm.imagem instanceof File) {
                try {
                    const result = await new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = (e) => resolve(e.target.result);
                        reader.onerror = (e) => reject(e);
                        reader.readAsDataURL(this.createForm.imagem);
                    });

                    const upload = await cloudinary.uploader.upload(result, {
                        folder: 'Quanta Shop',
                        resource_type: 'image'
                    });

                    this.createForm.imagemUrl = upload.secure_url;
                    this.createForm.imagem = upload.secure_url;
                } catch (error) {
                    console.error('Error uploading image:', error);
                    this.$snotify.error('Erro ao fazer upload da imagem');
                }
            }
        },
        async createCarrossel() {
            this.$loading(true);

            try {
                const response = await http.post('/v2/carousels/', this.createForm);
                if (response.data && response.data.success) {
                    this.$snotify.success('Carrossel criado com sucesso');
                    await this.obterCarrosseis();
                } else {
                    throw new Error('Falha ao criar o carrossel');
                }
            } catch (error) {
                const errorMessages = http.mensagensErro(error);
                const allErrors = [error.message, ...errorMessages].join('. ');
                this.$snotify.error(allErrors);
            } finally {
                this.$loading(false);
            }
        },
        validateCreateForm() {
            // Add your validation logic here
            return true;
        },
    },
};