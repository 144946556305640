import http from "@/_helper/api-services";
import "./MeusDados.scss";
import DadosPessoais from "@/components/DadosPessoais/DadosPessoais.vue";
import Endereco from "@/components/Endereco/Endereco.vue";
import FotoPerfil from "@/components/FotoPerfil/FotoPerfil.vue";
import PerfilPainel from "@/components/PerfilPainel/PerfilPainel.vue";
import Seguranca from "@/components/Seguranca/Seguranca.vue";

export default {
    name: "MeusDados",
    data() {
        return {
            documentoPreenchido: false,
            dadosPessoais: {
                nome: String(),
                login: String(),
                celular: String(),
                documento: '',
                repitaSenha: String(),
                senhaAntiga: String(),
                novaSenha: String(),
                novaSenhaConfirma: String(),
                rg: String(),
                orgaoEmissorRg: String(),
                estadoRg: String(),
                patrocinador: String(),
                nomeSocial: String(),
                genero: null,
                dataNascimento: Date(),
                preCadastro: Boolean(),
                loginAlterado: Boolean(),
            },
            dadosEndereco: {
                idEndereco: Number,
                rua: String(),
                numero: String(),
                bairro: String(),
                cep: String(),
                complemento: String(),
                idCidade: Number(),
                cidade: Object,
            },
            fotoPerfil: {
                file: null,
                imagem: String(),
                urlImg: String(),
                blob: String(),
            },
            erroForm: false,
            menuMeusDados: "dados-pessoais",
        };
    },
    computed: {

    },
    watch: {
        "fotoPerfil.file": function (e) {
            var reader = new FileReader();
            reader.readAsDataURL(e);
            reader.onload = () => {
                this.fotoPerfil.imagem = reader.result;
            };
        },
    },
    components: {
        DadosPessoais,
        Endereco,
        FotoPerfil,
        PerfilPainel,
        Seguranca
    },
    beforeMount() {
        this.buscarDadosPessoais();
        this.buscarUsuarioEndereco();
        this.buscarFotoPerfil();
    },
    methods: {
        buscarDadosPessoais() {
            this.$loading(true);
            http.get("/user/obterDadosPessoais").then((response) => {
                if (response) {
                    this.dadosPessoais.nome = response.data.nome;
                    this.dadosPessoais.email = response.data.email;
                    this.dadosPessoais.celular = response.data.celular;
                    this.dadosPessoais.login = response.data.login;
                    this.dadosPessoais.documento = response.data.documento;
                    this.dadosPessoais.rg = response.data.rg;
                    this.dadosPessoais.orgaoEmissorRG = response.data.orgaoEmissorRG;
                    this.dadosPessoais.estadoRG = response.data.estadoRG;
                    this.dadosPessoais.patrocinador = response.data.loginPatrocinador;
                    this.dadosPessoais.loginAlterado = response.data.loginAlterado;
                    this.dadosPessoais.dataNascimento =new Date(response.data.dataNascimento);
                    this.dadosPessoais.genero = response.data.genero;
                    this.dadosPessoais.nomeSocial = response.data.nomeSocial;
                    this.dadosPessoais.preCadastro = response.data.preCadastro;

                    this.documentoPreenchido = this.dadosPessoais.documento !== '' && this.dadosPessoais.documento !== null;

                }
                // Recupera o objeto armazenado no localStorage com a chave "user"
                const user = JSON.parse(localStorage.getItem('user'));

                // Verifica se o objeto foi encontrado
                if (user) {
                // Atualiza a propriedade preCadastro
                user.preCadastro = this.dadosPessoais.preCadastro; // ou qualquer outro valor desejado
                
                // Salva o objeto atualizado de volta no localStorage
                localStorage.setItem('user', JSON.stringify(user));
                } else {
                console.log('Usuário não encontrado no localStorage');
                }
                this.$loading(false);
            });
        },
        buscarUsuarioEndereco() {
            this.$loading(true);
            http.get("/UsuarioEndereco/obterUsuarioEndereco").then(
                (response) => {
                    if (response) {
                        this.dadosEndereco.rua = response.data.rua;
                        this.dadosEndereco.numero = response.data.numero;
                        this.dadosEndereco.bairro = response.data.bairro;
                        this.dadosEndereco.cep = response.data.cep;
                        this.dadosEndereco.complemento =
                            response.data.complemento;
                        this.dadosEndereco.idCidade = response.data.idCidade;
                        this.dadosEndereco.cidade = response.data.cidade;
                        this.dadosEndereco.idEndereco =
                            response.data.idEndereco;
                    }
                    this.$loading(false);
                }
            );
        },
        buscarFotoPerfil() {
            this.$loading(true);
            this.fotoPerfil.imagem = "";
            this.fotoPerfil.file = null;

            http.get("/user/obterFotoPerfil").then((response) => {
                if (response) {
                    this.fotoPerfil.urlImg = response.data.urlImg;
                }
                this.$loading(false);
            });
        },
        salvarDados(which) {
            if (which == "dados-pessoais") this.editarUsuario();
            else if (which == "endereco") this.editarEndereco();
            else if (which == "foto-perfil") this.editarImagem();
        },
        editarUsuario() {
            if (this.checkFormDadosPessoais()) {
                this.$loading(true);

                http.post("/user/editarUsuario", this.dadosPessoais)
                    .then(
                        () => {
                            let user = this.$store.getters[
                                "autenticacao/getLogin"
                            ].user;
                            user.username = this.dadosPessoais.nome;
                            localStorage.removeItem("user");
                            localStorage.setItem("user", JSON.stringify(user));
                            this.$snotify.success(
                                "Cadastro atualizado com sucesso."
                            );
                            this.buscarDadosPessoais();
                            //this.displayNotification(response.data.message);
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        editarEndereco() {
            if (this.checkFormEndereco()) {
                this.$loading(true);
                http.update(
                    "/UsuarioEndereco/editarUsuarioEndereco",
                    this.dadosEndereco
                )
                    .then(
                        (response) => {
                            this.displayNotification(response.data.message);
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        editarImagem() {
            if (this.checkFormImagem()) {
                this.$loading(true);
                http.post("/user/updateImage", this.fotoPerfil)
                    .then(
                        (response) => {
                            let user = this.$store.getters[
                                "autenticacao/getLogin"
                            ].user;
                            user.username = this.dadosPessoais.nome;
                            user.perfil = "E";
                            user.urlImg = response.data.url;
                            this.fotoPerfil.urlImg = response.data.url;

                            localStorage.removeItem("user");
                            localStorage.setItem("user", JSON.stringify(user));

                            this.$loading(true);
                            this.displayNotification(response.data.message);
                        },
                        (error) => {
                            for (const erro of http.mensagensErro(error)) {
                                this.$snotify.error(erro);
                            }
                        }
                    )
                    .finally(() => {
                        this.$loading(false);
                    });
            }
        },
        checkFormDadosPessoais() {
            if (
                !this.dadosPessoais.nome ||
                !this.dadosPessoais.celular ||
                this.dadosPessoais.novaSenha != this.dadosPessoais.novaSenhaConfirma
            ) {
                this.erroForm = true;
                this.$snotify.error("Preencha os campos obrigatórios.");
                return false;
            }

            return true;
        },
        checkFormEndereco() {
            if (
                !this.dadosEndereco.rua ||
                !this.dadosEndereco.numero ||
                !this.dadosEndereco.bairro ||
                !this.dadosEndereco.cep ||
                !this.dadosEndereco.idCidade
            ) {
                this.erroForm = true;
                this.$snotify.error("Preencha os campos obrigatórios.");
                return false;
            }

            return true;
        },
        checkFormImagem() {
            if (!this.fotoPerfil.file) {
                this.erroForm = true;
                this.$snotify.error("Selecione uma nova imagem para salvar.");
                return false;
            }

            return true;
        },
        voltarDashboard() {
            this.$router.push("/");
        },

        displayNotification(message) {
            this.$snotify.async(
                message,
                "",
                () =>
                    new Promise((resolve, reject) => {
                        setTimeout(function () {
                            window.location.reload();
                        }, 3000);
                    })
            );
        },
        formatDateTime(value) {
            if (!value) return "";
            const date = new Date(value);
            const formattedDate = date.toLocaleDateString("pt-BR", {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            const formattedTime = date.toLocaleTimeString("pt-BR", {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            return `${formattedDate} ${formattedTime}`;
        },
    },
};
