import http from "@/_helper/api-services";
import tabelaBase from "@/components/TabelaBase/tabelaBase.vue";
import Ecossistemas from "./Ecossistemas.vue";
import { formatPhone } from '@/filters/filters';

export default {
    name: "Ecossistemas",
    components: { tabelaBase },
    data() {
        return {
            dadosFaturamento: [],
            camposFaturamento: [
                { key: 'Período', label: 'Período', thClass: 'text-center', tdClass: 'text-center' },
                { key: 'FaturamentoFormatado', label: 'Faturamento', thClass: 'text-center', tdClass: 'text-right' }
            ],
            totalFaturamento: 0,
            totalFaturamentoFormatado: '',
            ecossistemas: {
                items: [],
                title: "Ecossistemas",
                fields: [
                    {
                        key: "nome",
                        label: "Nome",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "regiao",
                        label: "Região",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "dataCadastro",
                        label: "Data de criação",
                        sortable: true,
                        class: "text-center",
                        formatter: (value) => this.formatDate(value),
                    },
                    {
                        key: "idUsuarioGerente",
                        label: "Usuário Gerente",
                        sortable: true,
                        class: "text-center",
                    },
                    {
                        key: "ativo",
                        label: "Status",
                        sortable: true,
                        class: "text-center",
                        formatter: (val) => {
                            if (val) return "Ativo";
                            return "Inativo";
                        },
                    },
                    {
                        key: "acoes",
                        label: "Ações",
                        class: "text-center",
                        sortable: false,
                    },
                ],
                sortBy: "nome",
                sortDesc: false,
                exportExcel: false,
                perPage: 10,
            },
            filtro: {
                nome: String(),
                ativo: true,
            },
            exportFields: {
                Nome: "nome",
                "Nome Pai": "nomePai",
                "Data Criação": "dataCriacao",
                "Última Atualizaçãp": "dataAtualizacao",
                Status: {
                    field: "ativo",
                    callback: (value) => {
                        return value ? "Ativo" : "Inativo";
                    },
                },
            },
            exportTable: [],
            idEcossistemaExclusao: null,
            dadosEmpresas: [],
            ecossistemaSelecionado: null,
        };
    },
    async beforeMount() {
        await this.buscarEcossistemas();
    },
    methods: {
        async buscarEcossistemas() {
            await http
                .get(`/Ecossistema/obterEcossistemas`, this.filtro)
                .then((response) => {
                    console.log(response);
                    this.ecossistemas.items = response.data;

                    console.log(this.ecossistemas);
                    this.exportTable = response.data;
                });
        },
        criarEcossistema() {
            window.location.href = "/painel/admin/ecossistemas/criar-ecossistema";
        },
        editarEcossistema(idEcossistema) {
            window.location.href =
                "/painel/admin/ecossistemas/editar-ecossistema?idEcossistema=" +
                idEcossistema;
        },
        modalDelete(idEcossistema) {
            this.idEcossistemaExclusao = idEcossistema;
            this.$bvModal.show("confirmDelete");
        },
        excluirEcossistema() {
            this.$loading(true);
            http.delete(
                `/Ecossistema/deletarEcossistema/${this.idEcossistemaExclusao}`
            )
                .then(
                    (success) => {
                        this.$snotify.success(
                            "Ecossistema excluído com sucesso."
                        );
                        this.obterParceiros();
                    },
                    (error) => {
                        for (const erro of http.mensagensErro(
                            error,
                            "Erro ao excluir ecossistema."
                        )) {
                            this.$snotify.error(erro);
                        }
                    }
                )
                .finally(() => {
                    this.$loading(false);
                    this.$bvModal.hide("confirmDelete");
                });
        },
        abrirModalFaturamento(idEcossistema) {
            this.buscarDadosFaturamento(idEcossistema).then(data => {
                this.dadosFaturamento = data.items;
                this.totalFaturamento = data.total;
                this.totalFaturamentoFormatado = data.totalFormatado;
                this.$bvModal.show('faturamentoModal');
            });
        },
        async buscarDadosFaturamento(idEcossistema) {
            const response = await http.get(`/admin/obter-faturamento-ecossistema/${idEcossistema}`);
            const data = response.data;

            // Calcula o total de faturamento
            let total = 0;
            data.forEach(item => {
                total += item.Faturamento;
            });

            // Retorna os dados formatados e o total
            return {
                items: data.map(item => ({
                    Período: item.Período,
                    FaturamentoFormatado: item.FaturamentoFormatado
                })),
                total: total,
                totalFormatado: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)
            };
        },
        // mapearCategorias() {
        //     window.location.href =
        //         "/painel/admin/categorias/mapear-categorias";
        // },
        formatDate(data) {
            if (data) {
                let dateTime = new Date(data);
                return (
                    dateTime.toLocaleDateString() +
                    " " +
                    dateTime.toLocaleTimeString()
                );
            }
        },
        dataMenos30() {
            var result = new Date();
            result.setDate(result.getDate() + -30);
            return result;
        },
        async abrirModalEmpresas(idEcossistema) {
            this.ecossistemaSelecionado = this.ecossistemas.items.find(
                eco => eco.idEcossistema === idEcossistema
            );
            this.buscarDadosEmpresas(idEcossistema).then(data => {
                this.dadosEmpresas = data;
                this.$bvModal.show('empresasModal');
            });
        },
        async buscarDadosEmpresas(idEcossistema) {
            const response = await http.get(`/admin/obter-empresas-ecossistema/${idEcossistema}`);
            return response.data;
        }
    },
};
