import Vue from "vue";
import Router from "vue-router";
import { jwtDecode } from "jwt-decode";
import NotFound from "./views/PageNotFound/PageNotFound.vue";
// import Login from "./views/Login/Login.vue";
// import Cadastro from "./views/Cadastro/Cadastro.vue";
// import RecuperarSenha from "./views/RecuperarSenha/RecuperarSenha.vue";
// import ResetPassword from "./views/ResetPassword/ResetPassword.vue";
import ParceiroDireto from "./views/ParceiroDireto/ParceiroDireto.vue";
import Logout from "./views/Logout/logout.vue";
import ConfirmEmail from "./views/ConfirmEmail/confirmEmail.vue";
import Produtos from "./views/Produtos/produtos.vue";
import Assinatura from "./views/Assinatura/assinatura.vue";
import DetalhamentoPlano from "./views/DetalhamentoPlano/DetalhamentoPlano.vue";
import DetalhamentoCompra from "./views/DetalhamentoCompra/DetalhamentoCompra.vue";
import MeusDados from "./views/MeusDados/MeusDados.vue";
import MinhasCompras from "./views/MinhasCompras/MinhasCompras.vue";
import Usuarios from "./views/Admin/Usuarios/usuarios.vue";
import Categorias from "./views/Admin/Categorias/Categorias.vue";
import Ecossistemas from "./views/Admin/Ecossistemas/Ecossistemas.vue";
import Carrosseis from "./views/Admin/Carrossel/Carrossel.vue";
import EditarCarrossel from "./views/Admin/Carrossel//EditarCarrossel/EditarCarrossel.vue";
import CriarCategoria from "./views/Admin/Categorias/CriarCategoria/Categoria.vue";
import EditarCategoria from "./views/Admin/Categorias/EditarCategoria/Categoria.vue";
import CriarEcossistema from "./views/Admin/Ecossistemas/CriarEcossistema/Ecossistema.vue";
import EditarEcossistema from "./views/Admin/Ecossistemas/EditarEcossistema/Ecossistema.vue";
import MapearCategorias from "./views/Admin/Categorias/MapearCategorias/MapearCategorias.vue";
import SemPermissao from "./views/SemPermissao/semPermissao.vue";
import AlterarDadosUsuario from "./views/Admin/AlterarDadosUsuario/alterarDadosUsuario.vue";
import Portal from "./views/Portal/portal.vue";
import Home from "./views/Home/Home.vue";
import JornadaMetaMinuto from "./views/JornadaMetaMinuto/Index.vue";
import MinhaRede from "./views/MinhaRede/minhaRede.vue";
import Comunicados from "./views/Admin/Comunicados/comunicados.vue";
import CadastroComunicado from "./views/Admin/CadastroComunicado/cadastroComunicado.vue";
import Financeiro from "./views/Financeiro/financeiro.vue";
import Pagamentos from "./views/Admin/Pagamentos/pagamentos.vue";
import QuemSomos from "./views/QuemSomos/quemSomos.vue";
import ComoFunciona from "./views/ComoFunciona/comoFunciona.vue";
import PainelAdmin from "./views/Admin/Painel/painelAdmin.vue";
import Graduacoes from "./views/Graduacoes/Graduacoes.vue";
import Credenciamento from "./views/Admin/Credenciamento/Credenciamento.vue";
import Cupons from "./views/Cupons/cupons.vue";
import Performance from "./views/Performance/Performance.vue";
import Faq from "./views/Faq/Faq.vue";
import Privacidade from "./views/Privacidade/Privacidade.vue";
import RecusaPrivacidade from "./views/RecusaPrivacidade/RecusaPrivacidade.vue";
import GerenciarGrupos from "./views/Admin/GerenciarGrupos/GerenciarGrupos.vue";
import ContasBancarias from "./views/ContasBancarias/contasBancarias.vue";
import PedidoPlanos from "./views/Admin/PedidoPlanos/PedidoPlanos.vue";
import Rede from "./views/Admin/Rede/Rede.vue";
import ComprasAdmin from "./views/Admin/Compras/compras.vue";
import MaisVendas from "./views/MaisVendas/maisVendas.vue";
import FinalizarCredenciamento from "./views/FinalizarCredenciamento/finalizarCredenciamento.vue";
import MaisVendasLogado from "./views/MaisVendasLogado/maisVendasLogado.vue";
import SuporteAdmin from "./views/Admin/SuporteAdmin/SuporteAdmin.vue";
import Suporte from "./views/Suporte/Suporte.vue";
import SolicitacaoSuporte from "./views/SolicitacaoSuporte/SolicitacaoSuporte.vue";
import LojasFisicas from "./views/LojasFisicas/lojasFisicas.vue";
import PainelComerciante from "./views/_Comerciante/PainelComerciante/PainelComerciante.vue";
import DadosCredenciamento from "./views/_Comerciante/DadosCredenciamento/DadosCredenciamento.vue";
import MinhasVendas from "./views/_Comerciante/MinhasVendas/minhasVendas.vue";
import Vendas from "./views/_Comerciante/Vendas/Vendas.vue";
import MinhasFaturas from "./views/_Comerciante/MinhasFaturas/minhasFaturas.vue";
import RelatorioDeFaturas from "./views/Admin/RelatorioDeFaturas/relatorioDeFaturas.vue";
import RelatorioDeAnunciantes from "./views/Admin/RelatorioDeAnunciantes/relatorioDeAnunciantes.vue";
import DetalhamentoSuporteAdmin from "./views/Admin/DetalhesSuporte/DetalhesSuporte.vue";
import DetalhamentoSuporte from "./views/DetalhesSuporte/DetalhesSuporte.vue";
import PainelParceiro from "./views/_Comerciante/PainelParceiros/PainelParceiros.vue";
import CriarParceiro from "./views/_Comerciante/PainelParceiros/CriarParceiro/CriarParceiro.vue";
import EditarParceiro from "./views/_Comerciante/PainelParceiros/EditarParceiro/EditarParceiro.vue";
import LojaCredenciada from "./views/LojaCredenciada/LojaCredenciada.vue";
import DivulgacaoApp from "./views/DivulgacaoApp/DivulgacaoApp.vue";
import TelaApp from "./views/Admin/Config/TelaApp/ConfigTelaApp.vue";
import Planos from "./views/Admin/Config/Planos/Planos.vue";
import EditarPlano from "./views/Admin/Config/EditarPlano/EditarPlano.vue";
import CadastrarPlano from "./views/Admin/Config/CadastrarPlano/CadastrarPlano.vue";
import ConfigTelaApp from "./views/Admin/Config/TelaApp/TelaApp.vue";
import EditarPedido from "./views/Admin/Pedidos/EditarPedido.vue";
import FaqAdmin from "./views/Admin/FaqAdmin/FaqAdmin.vue";
import FaqEditar from "./views/Admin/FaqEditar/FaqEditar.vue";
import AtivarProduto from "./views/Admin/Usuarios/UsuarioPlano/UsuarioPlano.vue";
import EditarLojas from "./views/Admin/Config/EditarLojas/EditarLojas.vue";
import Lojas from "./views/Admin/Config/Lojas/Lojas.vue";
import Tutoriais from "./views/Admin/Config/Tutoriais/Tutoriais.vue";
import CadastrarTutorial from "./views/Admin/Config/CadastrarTutorial/CadastrarTutorial.vue";
import EditarTutorial from "./views/Admin/Config/EditarTutorial/EditarTutorial.vue";
import TutoriaisUsuario from "./views/TutoriaisUsuario/TutoriaisUsuario.vue";
import MaterialApoioAdmin from "./views/Admin/MaterialApoioAdmin/MaterialApoioAdmin.vue";
import MaterialApoioEditar from "./views/Admin/MaterialApoioEditar/MaterialApoioEditar.vue";
import MaterialApoio from "./views/MaterialApoio/MaterialApoio.vue";
import FaqLogado from "./views/FaqLogado/FaqLogado.vue";
import Desempenho from "./views/Desempenho/Desempenho.vue";
import DesempenhoDetalhado from "./views/DesempenhoDetalhado/DesempenhoDetalhado.vue";
import LancamentoAdmin from "./views/Admin/Lancamentos/Lancamentos.vue";
import ConfiguracaoAdmin from "./views/Admin/Config/Configuracao/Configuracao.vue";
import EditarConfiguracaoAdmin from "./views/Admin/Config/EditarConfig/EditarConfig.vue";
import MeusCupons from "./views/CuponCashbackUsuario/CuponCashbackUsuario.vue";
import GerarCupons from "./views/GerarCuponCashback/GerarCuponCashback.vue";
import LoginGoogle from "./views/LoginGoogle/LoginGoogle.vue";
import CadastroGoogle from "./views/CadastroGoogle/CadastroGoogle.vue";
import LojasCredenciados from "./views/Admin/Config/LojasFisicas/LojasFisicas.vue";
import EditarLojasCredenciados from "./views/Admin/Config/EditarLojasFisicas/EditarLojasFisicas.vue";
import RelatorioCashback from "./views/Admin/RelatorioCashback/relatorioCashback.vue";
import InserirCupomFiscal from "./views/InserirCupomFiscal/InserirCupomFiscal.vue";
import AprovarCupomFiscal from "./views/_Comerciante/AprovarCupomFiscal/AprovarCupomFiscal.vue";
import CuponsCompraUsuario from "./views/Admin/CuponsCompraUsuario/CuponsCompraUsuario.vue";
import Credenciar from './views/Credenciamento/Index.vue';
import Redirecionar from "./views/Redirecionar/Index.vue";
import Aniversariantes from "./views/Admin/Aniversariantes/Aniversariantes.vue";
import CadastrosPorDia from "./views/Admin/CadastrosPorDia/CadastrosPorDia.vue";

function isTokenExpired(token) {
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decoded.exp < currentTime;
    } catch (e) {
        return true;
    }
}

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    linkExactActiveClass: "is-active",
    routes: [
        // {
        //     path: "/login",
        //     name: "Login",
        //     component: Login,
        // },
        // {
        //     path: "/cadastro",
        //     name: "Cadastro",
        //     component: Cadastro,
        // },
        // {
        //     path: "/forgot-password",
        //     name: "RecuperarSenha",
        //     component: RecuperarSenha,
        // },
        // {
        //     path: "/reset-password",
        //     name: "ResetPassword",
        //     component: ResetPassword,
        // },
        {
            path: "/reset-password/:token",
            name: "",
            component: Portal,
        },
        {
            path: "/logout",
            name: "Logout",
            component: Logout,
        },
        {
            path: "/",
            name: "Portal",
            component: Portal,
        },
        {
            path: "/empresa/:idEmpresa",
            name: "Cupons",
            component: Cupons,
        },
        {
            path: "/anunciante/",
            name: "LojaCredenciada",
            component: LojaCredenciada,
        },
        {
            path: "/register/:loginPatrocinador",
            name: "Cadastro por link",
            component: Portal,
        },
        {
            path: "/confirm-email/:tokenConfirmar",
            name: "Confirm Email",
            component: ConfirmEmail,
        },
        {
            path: "/no-permission",
            name: "Sem permissão",
            component: SemPermissao,
        },
        {
            path: "/quem-somos",
            name: "Quem somos",
            component: QuemSomos,
        },
        {
            path: "/como-funciona",
            name: "Como funciona",
            component: ComoFunciona,
        },
        {
            path: "/painel/faq",
            name: "Faq Logado",
            component: FaqLogado,
        },
        {
            path: "/faq",
            name: "Faq",
            component: Faq,
        },
        {
            path: "/painel/material-apoio",
            name: "MaterialApoio",
            component: MaterialApoio,
        },
        {
            path: "/mais-vendas/:login?",
            name: "Credenciamento", //"Mais Vendas",
            component: MaisVendas,
        },
        {
            path: "/finalizar-credenciamento/:idCredenciamento",
            name: "Finalizar Credenciamento",
            component: FinalizarCredenciamento,
        },
        {
            path: "/credenciar/:login?",
            name: "Credenciar",
            component: Credenciar,
        },
        {
            path: "/app",
            name: "Divulgação APP",
            component: DivulgacaoApp,
        },
        {
            path: "/painel",
            name: "Home",
            component: JornadaMetaMinuto,
        },
        {
            path: "/painel-2",
            name: "JornadaMetaMinuto",
            component: JornadaMetaMinuto,
        },
        {
            path: "/painel/meus-diretos",
            name: "Meus Diretos",
            component: ParceiroDireto,
        },
        {
            path: "/painel/minha-rede",
            name: "Minha rede",
            component: MinhaRede,
        },
        {
            path: "/painel/assinatura",
            name: "Assinatura",
            component: Assinatura,
        },
        {
            path: "/painel/planos",
            name: "Products",
            component: Produtos,
        },
        {
            path: "/painel/detalhamento-plano",
            name: "Detalhamento Plano",
            component: DetalhamentoPlano,
        },
        {
            path: "/painel/detalhamento-compra",
            name: "Detalhes da compra",
            component: DetalhamentoCompra,
        },
        {
            path: "/painel/meus-dados",
            name: "MeusDados",
            component: MeusDados,
        },
        {
            path: "/painel/minhas-compras/:codigoPedido?",
            name: "MinhasCompras",
            component: MinhasCompras,
        },
        {
            path: "/painel/financeiro",
            name: "Financeiro",
            component: Financeiro,
        },
        {
            path: "/painel/contas-bancarias",
            name: "Contas bancárias",
            component: ContasBancarias,
        },
        {
            path: "/painel/parceiros",
            name: "Parceiros",
            component: PainelParceiro,
        },
        {
            path: "/painel/parceiros/novo-parceiro",
            name: "Criar Parceiro",
            component: CriarParceiro,
        },
        {
            path: "/painel/parceiros/editar-parceiro",
            name: "Editar Parceiro",
            component: EditarParceiro,
        },
        {
            path: "/painel/meus-credenciamentos",
            name: "Meus credencimentos",
            component: MaisVendasLogado,
        },
        {
            path: "/painel/admin",
            name: "Painel Administrativo",
            component: PainelAdmin,
        },
        {
            path: "/painel/admin/usuarios",
            name: "Usuarios",
            component: Usuarios,
        },
        {
            path: "/painel/admin/categorias",
            name: "Categorias",
            component: Categorias,
        },
        {
            path: "/painel/admin/ecossistemas",
            name: "Ecossistemas",
            component: Ecossistemas,
        },
        {
            path: "/painel/admin/carrosseis",
            name: "Carrosseis",
            component: Carrosseis,
        },
        {
            path: "/painel/admin/carrosseis/editar-carrossel",
            name: "Editar Carrossel",
            component: EditarCarrossel,
        },
        {
            path: "/painel/admin/ecossistemas/editar-ecossistema",
            name: "EditarEcossistema",
            component: EditarEcossistema,
        },
        {
            path: "/painel/admin/ecossistemas/criar-ecossistema",
            name: "CriarEcossistema",
            component: CriarEcossistema,
        },
        {
            path: "/painel/admin/categorias/editar-categoria",
            name: "EditarCategoria",
            component: EditarCategoria,
        },
        {
            path: "/painel/admin/categorias/criar-categoria",
            name: "CriarCategoria",
            component: CriarCategoria,
        },
        {
            path: "/painel/admin/categorias/mapear-categorias",
            name: "MapearCategorias",
            component: MapearCategorias,
        },
        {
            path: "/painel/admin/pagamentos",
            name: "Pagamentos",
            component: Pagamentos,
        },
        {
            path: "/painel/admin/alterar-dados-usuario/:idUsuario",
            name: "Alterar dados usuário",
            component: AlterarDadosUsuario,
        },
        {
            path: "/painel/admin/comunicados",
            name: "Comunicados",
            component: Comunicados,
        },
        {
            path: "/painel/admin/comunicados/cadastrar/:idMensagem?",
            name: "Cadastro de comunicados",
            component: CadastroComunicado,
        },
        {
            path: "/painel/graduacoes",
            name: "Graduações",
            component: Graduacoes,
        },
        {
            path: "/painel/admin/credenciamento",
            name: "Credenciamentos",
            component: Credenciamento,
        },
        {
            path: "/painel/admin/gerenciar-grupos",
            name: "Gerenciar grupos",
            component: GerenciarGrupos,
        },
        {
            path: "/painel/performance",
            name: "Performance",
            component: Performance,
        },
        {
            path: "/painel/desempenho",
            name: "Desempenho",
            component: Desempenho,
        },
        {
            path: "/painel/desempenho-detalhado",
            name: "Desempenho Detalhado",
            component: DesempenhoDetalhado,
        },
        {
            path: "/painel/admin/pedidos",
            name: "Relatório de pedidos de BAF",
            component: PedidoPlanos,
        },
        {
            path: "/painel/admin/rede",
            name: "Rede usuários",
            component: Rede,
        },
        {
            path: "/painel/admin/compras",
            name: "Compras",
            component: ComprasAdmin,
        },
        {
            path: "/painel/admin/suporte",
            name: "Suporte Admin",
            component: SuporteAdmin,
        },
        {
            path: "/painel/admin/config/tela-app",
            name: "Tela App",
            component: TelaApp,
        },
        {
            path: "/painel/admin/config/planos",
            name: "Planos Admin",
            component: Planos,
        },
        {
            path: "/painel/admin/config/lojas",
            name: "Lojas Admin",
            component: Lojas,
        },
        {
            path: "/painel/admin/config/editar-plano",
            name: "Editar Plano Admin",
            component: EditarPlano,
        },
        {
            path: "/painel/admin/config/editar-loja",
            name: "Editar Loja Admin",
            component: EditarLojas,
        },
        {
            path: "/painel/admin/config/cadastrar-plano",
            name: "Cadastrar Plano Admin",
            component: CadastrarPlano,
        },
        {
            path: "/painel/admin/pedidos/editar",
            name: "Editar pedido",
            component: EditarPedido,
        },
        {
            path: "/painel/admin/usuarios/gerar-pedido",
            name: "Ativar produto",
            component: AtivarProduto,
        },
        {
            path: "/painel/admin/config/tutoriais",
            name: "Admin tutorial",
            component: Tutoriais,
        },
        {
            path: "/painel/admin/config/editar-tutorial",
            name: "Editar Tutorial Admin",
            component: EditarTutorial,
        },
        {
            path: "/painel/tutoriais-usuario",
            name: "Usuario tutorial",
            component: TutoriaisUsuario,
        },
        {
            path: "/painel/admin/config/cadastrar-tutorial",
            name: "Cadastrar Tutorial Admin",
            component: CadastrarTutorial,
        },
        {
            path: "/painel/admin/faq-admin",
            name: "Faq admin",
            component: FaqAdmin,
        },
        {
            path: "/painel/admin/faq-editar",
            name: "Faq editar",
            component: FaqEditar,
        },
        {
            path: "/painel/admin/configuracao",
            name: "Configuracao Admin",
            component: ConfiguracaoAdmin,
        },
        {
            path: "/painel/admin/configuracao/editar-configuracao",
            name: "Editar Configuracao Admin",
            component: EditarConfiguracaoAdmin,
        },
        {
            path: "/painel/suporte",
            name: "Suporte",
            component: Suporte,
        },
        {
            path: "/painel/suporte/solicitacao",
            name: "Solicitação de suporte",
            component: SolicitacaoSuporte,
        },
        {
            path: "/painel/comerciante",
            name: "Dashboard comerciante",
            component: PainelComerciante,
        },
        {
            path: "/painel/comerciante/dados-credenciamento",
            name: "Dados credenciamento",
            component: DadosCredenciamento,
        },
        {
            path: "/painel/comerciante/minhas-vendas",
            name: "Minhas vendas",
            component: MinhasVendas,
        },
        {
            path: "/painel/comerciante/vendas",
            name: "Registrar venda",
            component: Vendas,
        },
        {
            path: "/painel/comerciante/faturas",
            name: "Minhas faturas",
            component: MinhasFaturas,
        },
        {
            path: "/painel/comerciante/vendas-aguardando-aprovacao",
            name: "Vendas aguardando aprovação",
            component: AprovarCupomFiscal,
        },
        {
            path: "/painel/admin/relatorio-de-faturas",
            name: "Relatório de faturas",
            component: RelatorioDeFaturas,
        },
        {
            path: "/painel/admin/relatorio-de-anunciantes",
            name: "Relatório de Anunciantes",
            component: RelatorioDeAnunciantes,
        },
        {
            path: "/painel/admin/detalhesSolicitacao",
            name: "Verificar da solicitação",
            component: DetalhamentoSuporteAdmin,
        },
        {
            path: "/painel/suporte/detalhes",
            name: "Detalhes da solicitação",
            component: DetalhamentoSuporte,
        },
        {
            path: "/privacidade",
            name: "Privacidade",
            component: Privacidade,
        },
        {
            path: "/recusa-privacidade",
            name: "RecusaPrivacidade",
            component: RecusaPrivacidade,
        },
        {
            path: "/painel/admin/material-apoio-admin",
            name: "MaterialApoioAdmin",
            component: MaterialApoioAdmin,
        },
        {
            path: "/painel/admin/material-apoio-editar",
            name: "MaterialApoioEditar",
            component: MaterialApoioEditar,
        },
        {
            path: "/painel/admin/lancamentos",
            name: "Lancamentos",
            component: LancamentoAdmin,
        },
        {
            path: "/painel/admin/aprovar-compra-usuario",
            name: "CuponsCompraUsuario",
            component: CuponsCompraUsuario,
        },
        {
            path: "/painel/meus-cupons",
            name: "MeusCupons",
            component: MeusCupons,
        },
        {
            path: "/painel/gerar-cupons",
            name: "GerarCupons",
            component: GerarCupons,
        },
        {
            path: "/login-social/google",
            name: "LoginGoogle",
            component: LoginGoogle,
        },
        {
            path: "/cadastro-social/google",
            name: "CadastroGoogle",
            component: CadastroGoogle,
        },

        {
            path: "/painel/admin/lojas-credenciados",
            name: "LojasFisicas",
            component: LojasCredenciados,
        },
        {
            path: "/painel/admin/editar-loja-credenciado",
            name: "EditarLojasCredenciados",
            component: EditarLojasCredenciados,
        },
        {
            path: "/painel/admin/relatorio-cashback",
            name: "RelatorioCashback ",
            component: RelatorioCashback,
        },
        {
            path: "/painel/inserir-cupom",
            name: "InserirCupomFiscal",
            component: InserirCupomFiscal,
        },
        {
            path: "/painel/admin/aniversariantes",
            name: "Aniversariantes",
            component: Aniversariantes,
        },
        {
            path: "/painel/admin/cadastros-por-dia",
            name: "Cadastros por dia",
            component: CadastrosPorDia,
        },
        {
            path: "/auth",
            name: "Redirecionar",
            component: Redirecionar,
        },
        { path: "*", component: NotFound },
    ],
});

router.beforeEach((to, from, next) => {
    const authRequired = to.path.indexOf("/painel") != -1;
    const loggedIn = localStorage.getItem("user");
    const acessoRemoto = localStorage.getItem("userAdmin") != null;
    
    if (loggedIn) {
        const token = JSON.parse(loggedIn).token;
        const preCadastro = JSON.parse(loggedIn).preCadastro;
        
        if (isTokenExpired(token)) {
            if (to.path.indexOf("/logout") === -1) {
                return next("/logout");
            }
        }
        
        if(preCadastro){
            if (to.path !== "/painel/meus-dados" && to.path !== "/logout") {
                console.log("Redirecionando para /painel/meus-dados");
                return next("/painel/meus-dados");
            }
        }
    }    

    if (authRequired && !loggedIn) return next("/no-permission");

    if (acessoRemoto && !authRequired && to.path.indexOf("logout") == -1)
        return next("/painel");

    next();
});

export default router;
