<template>
  <b-container class="bg-white p-4">
    <div class="row">
      <div
        class="col-12 col-sm-2 col-md-2 col-lg-1 col-xl-1 d-flex align-items-center justify-content-center"
      >
        <img
          src="@/assets/imgs/game-controller.png"
          alt=""
          class="ranking-icon"
          style="width: 3.5rem"
        />
      </div>
      <div
        class="col-12 col-sm-10 col-md-10 col-lg-11 col-xl-11 d-flex flex-column align-items-center align-items-md-start justify-content-center"
      >
        <h5 class="font-weight-bold display-md-4 text-green">Quanta Game</h5>
        <p class="text-sm-justify hidden-mobile">
          No Quanta Shop, agora você pode participar de uma experiência
          gamificada. À medida que você completa objetivos, você avança de nível e
          desbloqueia recompensas exclusivas. É uma maneira divertida e
          interativa de aproveitar ao máximo a plataforma enquanto conquista
          benefícios adicionais!
        </p>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col cols="12" md="4" class="text-center mb-2 mb-md-0">
        <a
          target="_blank"
          class="d-block mt-auto"
          @click.prevent="selectLevel(1)"
        >
          <span
            :class="{
              'btn green-button-2 w-100': true,
              disabled: !isLevelAvailable(1),
            }"
          >
            <img
              v-show="isLevelAvailable(1)"
              src="@/assets/imgs/star.png"
              class="text-center align-items-center justify-content-center img-fluid mr-2 mb-1"
              alt="Estrela"
              style="width: 20px; height: 20px"
            />
            Iniciante
          </span>
        </a>
      </b-col>
      <b-col cols="12" md="4" class="text-center mb-2 mb-md-0">
        <a
          target="_blank"
          class="d-block mt-auto"
          @click.prevent="selectLevel(2)"
        >
          <span
            :class="{
              'btn green-button-2 w-100': true,
              disabled: !isLevelAvailable(2),
            }"
          >
            <!-- <img v-show="isLevelAvailable(2)" src="@/assets/imgs/Camada 19.png" class="text-center align-items-center justify-content-center img-fluid mr-2 mb-1" alt="Estrela" />  -->
            <img
              v-show="!isLevelAvailable(2)"
              src="@/assets/imgs/Camada 40 copiar.png"
              class="text-center align-items-center justify-content-center img-fluid mr-2 mb-1"
              alt="Estrela"
            />
            Intermediário
          </span>
        </a>
      </b-col>
      <b-col cols="12" md="4" class="text-center mb-2 mb-md-0">
        <a
          target="_blank"
          class="d-block mt-auto"
          @click.prevent="selectLevel(3)"
        >
          <span
            :class="{
              'btn green-button-2 w-100': true,
              disabled: !isLevelAvailable(3),
            }"
          >
            <!-- <img v-show="isLevelAvailable(3)" src="@/assets/imgs/Camada 19.png" class="text-center align-items-center justify-content-center img-fluid mr-2 mb-1" alt="Estrela" />  -->
            <img
              v-show="!isLevelAvailable(3)"
              src="@/assets/imgs/Camada 40 copiar.png"
              class="text-center align-items-center justify-content-center img-fluid mr-2 mb-1"
              alt="Estrela"
            />
            Avançado
          </span>
        </a>
      </b-col>
    </b-row>
    <b-row class="mb-3 align-items-center">
      <b-col xs="12" sm="2" class="text-center mb-2 mb-sm-0">
        <img
          src="@/assets/imgs/Camada 7 copiar.png"
          class="img-fluid"
          alt="Estrela"
        />
      </b-col>
      <b-col xs="12" sm="10" class="mb-2 mb-sm-0">
        <h2 class="title-color" v-show="selectedLevel == 1">Iniciante</h2>
        <p v-show="selectedLevel == 1">
          Parabéns! Você está começando sua jornada rumo ao sucesso. Complete os
          objetivos iniciais para desbloquear novas conquistas e acumular ganhos
          por minuto.
        </p>
        <h2 class="title-color" v-show="selectedLevel == 2">Intermediário</h2>
        <p v-show="selectedLevel == 2">
          Você está progredindo bem! Continue completando os desafios para se
          aproximar cada vez mais dos seus objetivos.
        </p>
        <h2 class="title-color" v-show="selectedLevel == 3">Avançado</h2>
        <p v-show="selectedLevel == 3">
          Excelente trabalho! Você está quase no topo. Complete os últimos
          desafios e conquiste o título de especialista no
          <span class="text-green"><strong>Quanta Shop</strong></span>
        </p>
      </b-col>
    </b-row>
    <b-row
      v-for="(item, index) in filteredProgressItems"
      :key="index"
      class="m-0"
    >
      <b-col cols="12" class="text-left">
        <span class="d-block mb-2 text-green"
          ><strong>{{ item.Descricao }}</strong></span
        >
        <b-progress :value="item.Total" :max="item.Quantidade" show-progress>
          <b-progress-bar
            :value="item.Total"
            :label="
              item.progressPercentage > 100
                ? 100 + '%'
                : item.progressPercentage + '%'
            "
          ></b-progress-bar>
        </b-progress>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import http from "@/_helper/api-services.js";
export default {
  data() {
    return {
      progressItems: [],
      totalPoints: 0,
      nextLevelPoints: 0,
      selectedLevel: 1,
    };
  },
  created() {
    this.buscarProgressoObjetivosUsuario();
  },
  methods: {
    async buscarProgressoObjetivosUsuario() {
      this.$loading(true);
      try {
        const success = await http.get(
          `/v2/dashboard/get-user-objectives-progress`
        );
        this.updateProgress(success.data.data);
      } catch (error) {
        console.error(
          "Erro ao buscar o progresso dos objetivos do usuário:",
          error
        );
        this.$snotify.error(
          "Erro ao buscar o progresso dos objetivos do usuário"
        );
      } finally {
        this.$loading(false);
      }
    },
    updateProgress(data) {
      this.progressItems = data.map((item) => {
        item.progressPercentage = Math.round(
          (item.Total / item.Quantidade) * 100
        );
        return item;
      });

      this.totalPoints = this.progressItems.reduce(
        (acc, item) =>
          acc + (item.Concluido === item.Quantidade ? item.Pontos : 0),
        0
      );

      this.nextLevelPoints = this.calculateNextLevelPoints();
    },
    calculateNextLevelPoints() {
      const levels = [1, 2, 3];
      let nextLevel = levels.find((level) => !this.isLevelAvailable(level + 1));

      if (nextLevel === undefined) {
        return 0; // Se todos os níveis estiverem disponíveis
      }

      return this.progressItems
        .filter((item) => item.Nivel === nextLevel)
        .reduce((acc, item) => acc + item.Pontos, 0);
    },
    isLevelAvailable(level) {
      if (level === 1) return true;
      // if (level === 2) return true;
      // if (level === 3) return true; // O nível 1 está sempre disponível // O nível 1 está sempre disponível

      const previousLevel = level - 1;
      const previousLevelItems = this.progressItems.filter(
        (item) => item.Nivel === previousLevel
      );

      // Verifica se todos os objetivos do nível anterior foram concluídos
      return previousLevelItems.every(
        (item) => item.Concluido === item.Quantidade
      );
    },
    selectLevel(level) {
      if (this.isLevelAvailable(level)) {
        this.selectedLevel = level;
      }
    },
  },
  computed: {
    filteredProgressItems() {
      return this.progressItems.filter(
        (item) => item.Nivel === this.selectedLevel
      );
    },
  },
};
</script>
<style scoped>
.b-avatar {
  background-color: #ffffff !important;
  border: 2px solid #eaeaea;
}

.text-center {
  text-align: center;
}

.text-muted {
  color: #6c757d !important;
}

.ml-2 {
  margin-left: 0.5rem;
}

.bg-white {
  background-color: white !important;
}

.green-button-2 {
  background-color: #1e5d68 !important;
  color: white !important;
  border: 0;
  border-radius: 6px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 26px 7px;
}

span.btn {
  font-size: .75rem !important;
}

.title-color {
  color: #98c73a !important;
}
</style>
