<template>
  <div class="message-ceo-modal" v-if="isOpen">
    <div class="modal-content">
      <h3>Enviar mensagem para o CEO</h3>
      <textarea
        ref="messageTextarea"
        v-model="message"
        placeholder="Digite sua mensagem aqui"
      ></textarea>
      <div class="button-group">
        <button @click="close" class="btn btn-secondary" :disabled="isLoading">
          Cancelar
        </button>
        <button
          @click="sendMessage"
          class="btn green-button-2"
          :disabled="isLoading"
        >
          {{ isLoading ? "Enviando..." : "Enviar" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/_helper/api-services.js";

export default {
  name: "MessageCeoModal",
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      message: "",
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isOpen) {
        this.$refs.messageTextarea.focus();
      }
    });
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.messageTextarea.focus();
        });
      }
    },
  },
  methods: {
    async sendMessage() {
      const trimmedMessage = this.message.trim();

      if (!trimmedMessage) {
        this.$snotify.error("Você precisa preencher a mensagem");
        return;
      }

      if (trimmedMessage.length < 10) {
        this.$snotify.error("A mensagem deve ter pelo menos 10 caracteres");
        return;
      }

      this.isLoading = true;
      try {
        const response = await http.post("/v2/dashboard/send-message-ceo", {
          message: this.message,
        });

        if (response.data.success) {
          this.$snotify.success(response.data.message);
          this.$emit("message-sent");
          this.close();
        } else {
          this.$snotify.error(response.data.message);
        }
      } catch (error) {
        const errorMessage =
          error.response.data.errorDetails.title || "Erro ao enviar a mensagem";
        this.$snotify.error(errorMessage);
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.$emit("close");
      this.message = "";
    },
  },
};
</script>

<style scoped>
.message-ceo-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 15px;
  padding: 10px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.button-group button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
