<template>
  <div class="container-fluid">
    <div class="card mt-3 shadow rounded-0 border-0">
      <div class="card-header rounded-0" style="background-color: #388695; color: #fff">
        <h5 class="text-uppercase mb-0">Aniversariantes do mês</h5>
      </div>
      <div class="card-body">
        <!-- Filtro de mês -->
        <div class="row mb-4">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="input-group">
              <select 
                v-model="mesSelecionado" 
                class="form-control rounded-0"
                @change="buscarAniversariantes"
              >
                <option
                  v-for="mes in mesesDoAno"
                  :key="mes.valor"
                  :value="mes.valor"
                >
                  {{ mes.nome }}
                </option>
              </select>
              <div class="input-group-append">
                <button 
                  class="btn btn-primary mr-2" 
                  @click="buscarAniversariantes"
                  :disabled="isLoading"
                >
                  <font-awesome-icon 
                    v-if="!isLoading" 
                    icon="search" 
                    class="mr-1" 
                  />
                  <font-awesome-icon 
                    v-else 
                    icon="spinner" 
                    spin 
                    class="mr-1" 
                  />
                  Pesquisar
                </button>
                <button 
                  class="btn btn-success rounded-0" 
                  @click="exportarExcel"
                  :disabled="aniversariantes.length === 0"
                >
                  <font-awesome-icon icon="file-excel" class="mr-1" />
                  Exportar Excel
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Loading overlay -->
        <div v-if="isLoading" class="text-center my-4">
          <font-awesome-icon icon="spinner" spin size="2x" />
          <p class="mt-2">Carregando...</p>
        </div>

        <!-- Table (show only when not loading) -->
        <div v-else class="table-responsive">
          <table class="table table-striped table-hover table-sm">
            <thead>
              <tr>
                <th class="text-center" style="color: #388695">Data do aniversário</th>
                <th class="text-center" style="color: #388695">Nome</th>
                <th class="text-center" style="color: #388695">Login</th>
                <th class="text-center" style="color: #388695">Email</th>
                <th class="text-center" style="color: #388695">Celular</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(usuario, index) in aniversariantes" :key="index">
                <td class="text-center" :title="usuario.DataNascimento">{{ usuario.DataNascimento }}</td>
                <td class="text-left" :title="usuario.Nome">{{ usuario.Nome }}</td>
                <td class="text-center" :title="usuario.Login">{{ usuario.Login }}</td>
                <td class="text-center" :title="usuario.Email">{{ usuario.Email }}</td>
                <td class="text-center">
                  <a
                    v-if="usuario.Celular"
                    :href="getWhatsAppLink(usuario)"
                    target="_blank"
                    class="text-success"
                  >
                    <font-awesome-icon
                      :icon="['fab', 'whatsapp']"
                      class="icon-whatsapp"
                    />
                    {{ usuario.Celular }}
                  </a>
                  <span v-else>{{ usuario.Celular }}</span>
                </td>
              </tr>
              <tr v-if="aniversariantes.length === 0">
                <td colspan="5" class="text-center">
                  Nenhum aniversariante encontrado
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/_helper/api-services";
import * as XLSX from 'xlsx';

export default {
  name: "Aniversariantes",
  data() {
    return {
      isLoading: false,
      mesSelecionado: new Date().getMonth() + 1,
      aniversariantes: [],
      mesesDoAno: [
        { valor: 1, nome: "Janeiro" },
        { valor: 2, nome: "Fevereiro" },
        { valor: 3, nome: "Março" },
        { valor: 4, nome: "Abril" },
        { valor: 5, nome: "Maio" },
        { valor: 6, nome: "Junho" },
        { valor: 7, nome: "Julho" },
        { valor: 8, nome: "Agosto" },
        { valor: 9, nome: "Setembro" },
        { valor: 10, nome: "Outubro" },
        { valor: 11, nome: "Novembro" },
        { valor: 12, nome: "Dezembro" },
      ],
    };
  },
  created() {
    this.buscarAniversariantes();
  },
  methods: {
    async buscarAniversariantes() {
      this.isLoading = true;
      try {
        const response = await ApiService.get("/Admin/obter-aniversariantes", {
          mes: this.mesSelecionado,
        });
        this.aniversariantes = response.data;
      } catch (error) {
        console.error("Erro ao buscar aniversariantes:", error);
      } finally {
        this.isLoading = false;
      }
    },
    getWhatsAppLink(usuario) {
      const phoneNumber = usuario.Celular.replace(/\D/g, "");
      const message = encodeURIComponent(
        `Feliz Aniversário ${usuario.Nome}! 🎉\n\nQue seu dia seja repleto de alegria e realizações. Parabéns!\n\nEquipe Quanta`
      );
      return `https://wa.me/55${phoneNumber}?text=${message}`;
    },
    exportarExcel() {
      const dados = this.aniversariantes.map(usuario => ({
        'Data do aniversário': usuario.DataNascimento,
        'Nome': usuario.Nome,
        'Login': usuario.Login,
        'Email': usuario.Email,
        'Celular': usuario.Celular || ''
      }));

      const ws = XLSX.utils.json_to_sheet(dados);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Aniversariantes');
      
      const mesAtual = this.mesesDoAno.find(mes => mes.valor === this.mesSelecionado).nome;
      XLSX.writeFile(wb, `Aniversariantes_${mesAtual}.xlsx`);
    }
  },
};
</script>
