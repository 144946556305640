<template>
  <div>
    <b-button
      class="btn green-button-2 text-uppercase w-100"
      size="lg"
      centered
      @click.prevent="openModal"
      ref="btnShow"
    >
      <font-awesome-icon :icon="['fas', 'share']" class="mr-2 icon" />
      Convide seus amigos
    </b-button>

    <b-modal
      :id="modalId"
      centered
      title="Aumente seus ganhos convidando seus amigos!"
      size="lg"
      hide-footer
    >
      <div class="instructions">
        <p>
          <strong
            >Quer maximizar seus ganhos com nosso programa de cashback?</strong
          >
          É simples! Ao compartilhar seu link de indicação exclusivo com amigos,
          familiares e colegas, você pode ganhar ainda mais com as compras que
          eles fazem. Veja como funciona:
        </p>

        <ol>
          <li>
            <strong>Compartilhe seu link de indicação:</strong> Após se
            cadastrar no nosso programa, você receberá um link de indicação
            único. Basta copiar e colar esse link em suas redes sociais, e-mails
            ou mensagens para convidar outras pessoas a usar nosso site.
          </li>
          <li>
            <strong>Seus amigos compram:</strong> Quando seus amigos realizarem
            compras em lojas parceiras, você receberá uma porcentagem do
            cashback gerado por essas transações.
          </li>
          <li>
            <strong>Ganhe mais com cada compra:</strong> A cada compra realizada
            pelos seus amigos, você acumulará mais cashback em sua conta. Não há
            limite para quantos amigos indicados você pode ter ou quanto pode
            ganhar!
          </li>
          <li>
            <strong>Acompanhe seus ganhos:</strong> Fique de olho no painel de
            controle da sua conta para acompanhar o cashback que você está
            gerando com suas indicações. É fácil ver o impacto das suas
            recomendações e acompanhar seu progresso.
          </li>
        </ol>

        <p>
          <strong>Comece Agora!</strong> Compartilhe seu link de indicação e
          comece a ganhar mais com o cashback das compras dos seus amigos. É uma
          forma simples e eficaz de aumentar seus ganhos enquanto ajuda outros a
          descobrirem as vantagens do nosso programa.
        </p>

        <p>
          Se tiver alguma dúvida ou precisar de assistência, nossa equipe de
          suporte está aqui para ajudar. Aproveite ao máximo o nosso programa e
          maximize seus ganhos hoje mesmo!
        </p>

        <!-- Add QR Code image -->
        <div v-if="qrCodeLoaded" class="text-center mt-4">
          <img
            :src="qrCodeUrl"
            alt="QR Code"
            class="qr-code-image"
            @load="onQRCodeLoad"
            @error="onQRCodeError"
          />
          <div v-if="qrCodeLoaded" class="mt-2">
            <b-button @click="downloadQRCode" size="sm" class="green-button-2">
              <b-icon icon="download"></b-icon> Baixar QR Code para compartilhar
            </b-button>
          </div>
        </div>

        <div class="mt-3">
          <b-input-group class="border-dashed">
            <b-form-input
              class="bg-transparent border-0 ml-2"
              type="text"
              disabled
              :value="`https://quantashop.com.br/register/${dadosUser.login}`"
            >
            </b-form-input>

            <b-input-group-append
              @click="
                copy(`https://quantashop.com.br/register/${dadosUser.login}`)
              "
            >
              <b-input-group-text
                class="bg-transparent border-0 cursor-pointer mr-2"
              >
                <b-icon icon="files" />
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>

          <div
            class="d-flex flex-row gap-2 align-items-center justify-content-center mt-4"
          >
            <button class="button-compartilhar" @click="share">
              <b-icon icon="share" /> Compartilhar
            </button>
            <button class="button-whatsapp" @click="shareOnWhatsApp">
              <b-icon icon="whatsapp" /> WhatsApp
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.dadosUser = this.$store.getters["autenticacao/getLogin"].user;

    if (this.dadosUser) {
      this.shareWhatsAppMessage = `Quanta Shop é uma plataforma digital que te ajuda a economizar e gerar renda através do consumo inteligente e fidelização. Você compra nas lojas credenciadas e recebe parte do valor gasto de volta, o chamado cashback.
                
Para participar, você faz um cadastro gratuito usando o meu link de indicação.
                
Vamos começar? Clique no meu link de indicação logo abaixo.
https://quantashop.com.br/register/${this.dadosUser.login}`;
    }

    this.generateQRCode();
  },
  data() {
    return {
      dadosUser: {},
      shareWhatsAppMessage: "",
      modalId: this.generateUniqueId(),
      qrCodeUrl: "",
      qrCodeLoaded: false,
    };
  },
  methods: {
    generateUniqueId() {
      return `modal-${Math.random().toString(36).substr(2, 9)}-${Date.now()}`;
    },
    openModal() {
      this.$root.$emit("bv::show::modal", this.modalId, "#btnShow");
    },
    share() {
      if (navigator.share) {
        navigator
          .share({
            title: "Convide seus amigos para o Quanta Shop",
            text: this.shareWhatsAppMessage,
          })
          .then(() => {
            // handle success
            //console.log("Shared content successfully.")
          })
          .catch((error) => {
            // Error handling
            //console.log("Error while sharing.", error)
          });
      } else {
        alert("Yout browser is not supporting WebShare API.");
      }
    },
    shareOnWhatsApp() {
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        this.shareWhatsAppMessage
      )}`;
      window.open(whatsappUrl, "_blank");
    },
    copy(link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.$snotify.success("Link copiado com sucesso.");
        })
        .catch((err) => {
          this.$snotify.error("Ocorreu um erro inesperado.");
        });
    },
    generateQRCode() {
      const link = `https://quantashop.com.br/register/${this.dadosUser.login}`;
      this.qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(
        link
      )}`;

      const img = new Image();
      img.onload = this.onQRCodeLoad;
      img.onerror = this.onQRCodeError;
      img.src = this.qrCodeUrl;
    },
    onQRCodeLoad() {
      this.qrCodeLoaded = true;
    },
    onQRCodeError() {
      this.qrCodeLoaded = false;
    },
    downloadQRCode() {
      fetch(this.qrCodeUrl)
        .then((response) => response.blob())
        .then((blob) => {
          saveAs(blob, "qrcode_indicacao.png");
        })
        .catch((error) => {
          console.error("Erro ao baixar o QR Code:", error);
          this.$snotify.error("Ocorreu um erro ao baixar o QR Code.");
        });
    },
  },
};
</script>

<style>
.green-button {
  background-color: #8ebf01 !important;
  border-radius: 13px !important;
  color: white !important;
  border-color: transparent !important;
  text-shadow: -1px -1px 1px black !important;
  text-align: center;
  letter-spacing: 3px;
  font-family: "Poppins", sans-serif !important;
  font-weight: bold;
  -webkit-box-shadow: 0px 5px 0px 0px rgba(77, 104, 1, 1) !important;
  -moz-box-shadow: 0px 5px 0px 0px rgba(77, 104, 1, 1) !important;
  box-shadow: 0px 5px 0px 0px rgba(77, 104, 1, 1) !important;
}

.green-button-2 {
  background-color: #1e5d68 !important;
  color: white !important;
  border: 0;
  border-radius: 6px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 26px 7px;
}

.green-button:hover {
  text-shadow: none;
}

.green-button-2:hover {
  background-color: #010f1c;
  color: white;
}

.modal-header {
  background-color: #1e5d68 !important;
  color: white !important;
}

.modal-title {
  color: white !important;
}

.instructions {
  padding: 10px;
}

ol {
  padding-left: 50px;
}

ol li {
  margin-bottom: 15px;
  list-style-type: decimal;
}

@media (max-width: 412px) {
  ol {
    padding-left: 20px;
  }
}
</style>
