import Vue from "vue";
import { store } from "./_store";
import App from "./App.vue";
import router from "./router";
import VueLoading from "vuejs-loading-plugin";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueMask from "vue-the-mask";
import { InlineSvgPlugin } from "vue-inline-svg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./assets/style/style.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import money from "v-money";
import VueSweetalert2 from "vue-sweetalert2";
import VueClipboard from "vue-clipboard2";
import Snotify, { SnotifyPosition, SnotifyStyle } from "vue-snotify";
import iconSucessNotification from "@/assets/icons/icon-success-notification.svg";
import iconErrorNotification from "@/assets/icons/icon-error-notification.svg";
import Vuelidate from "vuelidate";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { VueEditor } from "vue2-editor";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/pt-br';
import VueApexCharts from "vue-apexcharts";
import JsonExcel from "vue-json-excel";
import Loader from "./components/Loader/loader.vue";
import VueCookies from "vue-cookies";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import VueSlickCarousel from "vue-slick-carousel";
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtag from 'vue-gtag';
import { formatDate, formatCurrency, formatPhone, breakLine, truncateText } from './filters/filters';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);
Vue.filter('formatDate', formatDate);
Vue.filter('formatCurrency', formatCurrency);
Vue.filter('formatPhone', formatPhone);
Vue.filter('breakLine', breakLine);
Vue.filter('truncateText', truncateText);
 
Vue.use(VueGtag, {
  config: {
    id: 'G-3YM68FHXJW',
    params: {
      send_page_view: false
    }
  }
}, router)

Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMask);
Vue.use(InlineSvgPlugin);
Vue.use(vuetify);
Vue.use(money, { precision: 2 });
library.add(fas);
library.add(fab);
library.add(far);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueSweetalert2);
Vue.use(VueClipboard);
Vue.use(VueSlickCarousel);
Vue.use(Vuelidate);
Vue.component("VueSlider", VueSlider);
Vue.component("vue-editor", VueEditor);
Vue.component("date-picker", DatePicker);
Vue.component("apexchart", VueApexCharts);
Vue.component("downloadExcel", JsonExcel);


// Vue.use(VueReCaptcha, {
//   siteKey: '6Le-LeApAAAAALe1WkHPge57ihEVd4Wi0Adb02GA',
//   loaderOptions: {
//     useEnterprise: true,
//   }
// });

Vue.$cookies.config("7d");

const options = {
  toast: {
    position: SnotifyPosition.leftTop,
    pauseOnHover: true,
    showProgressBar: false,
    timeout: 5000,
    closeOnClick: false,
    preventDuplicates: true,
  },
  type: {
    [SnotifyStyle.success]: {
      icon: iconSucessNotification,
    },
    [SnotifyStyle.error]: {
      icon: iconErrorNotification,
    },
  },
};

Vue.use(Snotify, options);

Vue.use(VueLoading, {
  dark: true, // default false
  customLoader: Loader,
  // text: 'Carregando...', // default 'Loading'
  loading: true, // default false
  background: "rgba(255,255,255,.7)", // set custom background
  classes: ["loading-class"], // array, object or string
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
