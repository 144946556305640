import formLogin from "@/components/FormLogin/formLogin.vue";
import formRecuperarSenha from "@/components/FormRecuperarSenha/formRecuperarSenha.vue";

import "./portal.scss";

export default {
  name: "portal",
  components: {
    formLogin,
    formRecuperarSenha
  },
  data() {
    return {
      modalAction: null
    };
  },
  watch: {

  },
  computed: {

  },
  mounted() {
    this.$loading(false);
    this.hideElements();
  },
  methods: {
    hideElements() {
      const elementsToHide = ['.bar-top', '.box-header', '.footer'];
      elementsToHide.forEach(selector => {
        const elements = document.querySelectorAll(selector);
        elements.forEach(el => {
          el.style.display = 'none';
        });
      });
    },
    switchAction(action) {
      alert(action);
      this.modalAction = action;
    },
  },
};