import 'animate.css';
import { v4 as uuidv4 } from 'uuid';
import http from "@/_helper/api-services.js";
import BaseImageInput from '@/components/BaseImageInput/BaseImageInput.vue';
import FotoPerfil from "@/components/FotoPerfil/FotoPerfil.vue";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
import AutoComplete from "@/components/AutoComplete/AutoComplete.vue";

export default {
    name: "InserirCupomFiscal",
    components: {
        BaseImageInput,
        FotoPerfil,
        AutoComplete
    },
    filters: {
        formatCurrency(value) {
            return 'R$ ' + parseFloat(value).toFixed(2).replace('.', ',')
        },
        formatPercent(value) {
            if (!value) return ''
            return parseFloat(value * 100).toFixed(2).replace('.', ',') + '%'
        },
        formatDateTime(value) {
            if (!value) return '';

            // Converter a string para um objeto Date
            const date = new Date(value);

            // Extrair os componentes de data e hora
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês começa em 0
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            // Retornar a data e hora formatadas
            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        }
    },
    data() {
        return {
            isDisabled: true,
            isLoading: false,
            isSearch: false,
            existCpfCnpj: false,
            isSuccess: false,
            isError: false,
            isValid: true,
            selectedFile: null,
            imagePreviewUrl: null,
            msg: null,
            recognizedText: '',
            processedData: null,
            progress: 0,
            worker: null,
            isProcessing: false,
            comprovanteBase64: null,
            jsonResponse: '',
            tabIndex: 0,
            scanear: false,
            chaveManual: false,
            dadosManual: false,
            textoQRCode: "",
            chaveQRCode: "",
            html5QrCode: null,
            qrFormatsToSupport: [],
            erroChaveAcesso: false,
            option: true,
            previewImageFile: null,
            apiError: null,
            apiErrorMessage: null,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                precision: 2,
                masked: false,
            },
            percent: {
                decimal: ",",
                thousands: ".",
                suffix: " %",
                precision: 2,
                masked: false,
            },
            fotoPerfil: {
                file: null,
                imagem: String(),
                urlImg: String(),
                blob: String(),
            },
            erroForm: false,
            tipoSelecionado: { value: 0, text: "Selecione o meio de pagamento" },
            dadosCupom: {
                razao_social: null,
                nome_fantasia: null,
                CNPJ: null,
                dados_comprador: {
                    nome: null,
                    cpf: null
                },
                itens_compra: [
                    {
                        codigo: null,
                        descricao: null,
                        quantidade: null,
                        valor_unitario: null,
                        valor_total: null
                    }
                ],
                informacoes_compra: {
                    valor_total: null,
                    forma_pagamento: null,
                    valor_pago: null,
                    troco: null
                },
                dataCompra: null
            },
            venda: {
                valor: { value: 0, clicked: false },
                percentualCashback: { value: 0, clicked: false },
                documento: { value: "", clicked: false },
                dataVenda: { value: new Date(), clicked: false },
                descricao: { value: "", clicked: false },
                meioPagamento: {
                    value: 0,
                    text: "Selecione o meio de pagamento",
                    clicked: false,
                },
                compraUsuario: true,
                compraUsuarioAprovada: false,
                comprovanteCompra: "",
                idComerciante: null,
                chaveAcesso: { value: "", clicked: false },
                estabelecimento: null
            },
            tipoPagamento: [
                { value: 0, text: "Selecione o meio de pagamento" },
                { value: 14, text: "Cartão de crédito" },
                { value: 13, text: "Cartão de débito" },
                { value: 11, text: "Dinheiro" },
                { value: 15, text: "PIX" },
                { value: 10, text: "Saldo" },
            ],
            estabelecimentoSelecionado: {},
            estabelecimentos: [],
            comprasAguardandoAprovacao: [],
            valorTarifa: 0,
            valorMinimo: 0

        };
    },
    watch: {
        selectedFile(newFile) {
            this.previewImage();
        },
    },
    mounted() {
        // this.sortTipoPagamento();
        this.html5QrCode = new Html5Qrcode('reader');
        this.playVideo();
        const qrCodeSuccessCallback = (decodedText, decodedResult) => {
            /* handle success */
        };
        this.qrFormatsToSupport = [
            Html5QrcodeSupportedFormats.QR_CODE
        ];

        this.getComprasAguardandoAprovacao();
    },
    methods: {
        playVideo() {
            if (this.scanear) {
                this.startQrCodeScan();
            }
        },
        stopVideo() {
            this.stopQrCodeScan();
        },
        startQrCodeScan() {
            if (this.scanear) {
                const height = document.body.offsetHeight;
                const width = document.body.offsetWidth;
                const minWidth = Math.min(height, width);

                // const config = { fps: 30, qrbox: { width: minWidth * 0.667, height: minWidth * 0.667 }, aspectRatio:1.7777778 ,formatsToSupport: this.qrFormatsToSupport };
                const config = { fps: 30, qrbox: { width: 150, height: 150 }, aspectRatio: 1.7777778, formatsToSupport: this.qrFormatsToSupport };

                this.html5QrCode.start({ facingMode: "environment" }, config, this.onScanSuccess, this.onScanFailure);
            }
        },
        stopQrCodeScan() {
            if (this.html5QrCode.getState() == 2)
                this.html5QrCode.stop();
        },
        onScanSuccess(decodedText, decodedResult) {
            this.chaveAcessoQRCode = this.extrairHashQRCode(decodedText);
            this.textoQRCode = decodedText;
            this.checkChaveAcesso(this.chaveAcessoQRCode);
            this.stopQrCodeScan();
        },
        onScanFailure(error) {
        },
        scanFile() {
            const html5Qrcode = new Html5Qrcode('qr-input-file', { formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE] });
            const fileinput = document.getElementById('qr-input-file');
            fileinput.addEventListener('change', e => {
                if (e.target.files.length == 0) {
                    // No file selected, ignore 
                    return;
                }
                this.selectedFile = e.target.files[0];
                this.arquivoParaBase64(this.selectedFile);
                // Scan QR Code
                html5Qrcode.scanFile(this.selectedFile, true)
                    .then(decodedText => {
                        this.textoQRCode = decodedText;
                        this.chaveAcessoQRCode = this.extrairHashQRCode(decodedText);
                        this.checkChaveAcesso(this.chaveAcessoQRCode);
                    })
                    .catch(err => {
                        setTimeout(() => {
                            this.$snotify.error("Não foi possível ler o dados da nota, por favor preencha manualmente");
                            this.scanear = false;
                            this.chaveManual = true;
                            this.dadosManual = false;
                        }, 1000);
                    });
            });
        },
        arquivoParaBase64(arquivo) {
            return new Promise((resolve, reject) => {
                // Verifica se um arquivo foi passado
                if (!arquivo) {
                    reject(new Error('Nenhum arquivo selecionado.'));
                }
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                    this.comprovanteBase64 = reader.result;
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(arquivo);
            });
        },
        extrairHashQRCode(url) {
            const regex = /[?&]p=([^|]+)/;
            const match = regex.exec(url);

            if (match && match[1]) {
                return match[1];
            } else {
                setTimeout(() => {
                    if (!erroChaveAcesso) {
                        this.$snotify.error("Não foi possível ler o QRCode da nota, por favor preencha manualmente a chave de acesso");
                    }
                    this.scanear = false;
                    this.chaveManual = true;
                    this.dadosManual = false;
                    this.erroChaveAcesso = true;
                }, 1000);
                return null;
            }
        },
        handleBlur() {
            this.checkCnpj(this.venda.documento.value)
        },
        sortTipoPagamento() {
            this.tipoPagamento.sort((a, b) => {
                // Verifica se um dos itens tem id igual a 0 e posiciona-o primeiro
                if (a.id === 0) return -1;
                if (b.id === 0) return 1;
        
                // Ordenação padrão pelo texto
                if (a.text < b.text) {
                    return -1;
                } else if (a.text > b.text) {
                    return 1;
                }
                return 0;
            });
        },
        disabled() {
            const { valor, documento, descricao, meioPagamento } = this.venda;

            const isValorFilled = valor.value.length > 0;
            const isDocumentoFilled = documento.value.length > 0;
            const isDescricaoFilled = descricao.value.length > 0;
            const isMeioPagamentoFilled = meioPagamento.value > 0;
            const isMensagemDeErroFilled = this.apiErrorMessage != null && this.apiErrorMessage != "" 

            console.log('isValorFilled:', isValorFilled, 
                'isDocumentoFilled:', isDocumentoFilled, 
                'isDescricaoFilled:', isDescricaoFilled, 
                'isMeioPagamentoFilled:', isMeioPagamentoFilled, 
                'isMensagemDeErroFilled:', isMensagemDeErroFilled,
            "thisVenda: ", this.venda);

            this.isDisabled = !(isValorFilled && isDocumentoFilled && isDescricaoFilled && isMeioPagamentoFilled && !isMensagemDeErroFilled);
        },
        checkCnpj(ev) {
            if (!ev)
                return;

            this.isSearch = true;
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const cpfCnpj = ev.match(/\d/g, "").join("");

            if (cpfCnpj === user.documento) {
                this.$snotify.error("Não é possivel registrar venda com o mesmo email");
            }

            if (cpfCnpj.length === 0) {
                this.isSearch = false;
                this.existCpfCnpj = false;
            }

            if (cpfCnpj.length >= 11 && cpfCnpj.length <= 14) {
                http
                    .get(`/user/checkCpfCnpj/${cpfCnpj}`)
                    .then((existsEmail) => {
                        if (existsEmail.data) {
                            setTimeout(() => {
                                this.isSuccess = true;
                            }, 2000);
                            this.existCpfCnpj = existsEmail.data;
                        } else throw error();
                    })
                    .catch((error) => {
                        this.$snotify.error("Conta não encontrada");
                        setTimeout(() => {
                            this.isError = true;
                        }, 2000);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isSearch = false;
                            this.isSuccess = false;
                            this.isError = false;
                        }, 5000);
                    });
            }
            if (cpfCnpj.length >= 11 && cpfCnpj.length <= 14) {
                this.getDadosCredenciamento(cpfCnpj);
            }
        },
        checkChaveAcesso(ev) {
            this.isSearch = true;
            const chaveAcesso = ev.match(/\d/g, "").join("");

            if (chaveAcesso.length === 0) {
                this.isSearch = false;
                this.existCpfCnpj = false;
            }

            const newCompra = {};
            newCompra.chaveAcesso = chaveAcesso;
            newCompra.urlChaveDeAcessoNF = this.textoQRCode;
            newCompra.chaveManual = this.chaveManual == true ? true : false;

            if (chaveAcesso.length === 44) {
                http
                    .post("/Compra/inserirCupomFiscalSemDadosCompra", newCompra)
                    .then((success) => {
                        this.$snotify.success("Cupom fiscal registrado com sucesso!");
                        this.openModal();
                        this.stopQrCodeScan();
                        this.erroChaveAcesso = false;
                    })
                    .catch((error) => {
                        setTimeout(() => {
                            var notaCadastrada = this.handleApiError(error);
                            if (notaCadastrada && this.scanear == true) {
                                this.stopQrCodeScan();
                                this.startQrCodeScan();

                            } else {
                                this.stopQrCodeScan();
                                this.isError = true;
                                this.scanear = false;
                                this.chaveManual = true;
                                this.dadosManual = false;
                            }
                        }, 2000);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isSearch = false;
                            this.isSuccess = false;
                            this.isError = false;
                        }, 5000);
                    });
            }
        },
        async getDadosPessoais() {
            await http.get(`/user/obterDadosPessoais`)
                .then((response) => {
                    this.venda.documento.value = response.data.documento;
                })
        },
        async getDadosCredenciamento(cpfCnpj) {
            await http.get(`/credenciamento/obterDadosCredenciamento/${cpfCnpj}`)
                .then((response) => {
                    this.venda.percentualCashback.value = response.data.percentualCashback;
                    this.venda.idComerciante = response.data.idComerciante;
                    this.venda.estabelecimento = response.data.estabelecimento;
                })
        },
        buscarEstabelecimento(nome) {
            http.get(`/credenciamento/obterDadosCredenciamentoPeloNome/?nome=${nome}`)
                .then(response => this.estabelecimentos = response.data.data);
        },
        onSelect(estabelecimento) {
            this.venda.documento.value = estabelecimento.cnpj
            this.venda.idComerciante = estabelecimento.id;
            this.venda.estabelecimento = estabelecimento.name
            this.venda.percentualCashback.value = estabelecimento.percentage
        },
        checkFormImagem() {
            if (!this.fotoPerfil.file) {
                this.erroForm = true;
                this.$snotify.error("Selecione uma nova imagem para salvar.");
                return false;
            }

            return true;
        },
        async sendDocument() {
            this.isLoading = true;


        },
        processImage(event) {
            this.isProcessing = true;
            this.previewImage()
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const imageData = new Image();
                imageData.src = reader.result;
                this.comprovanteBase64 = reader.result;

            };

            reader.readAsDataURL(file);
        },
        previewImage() {
            const file = this.selectedFile;
            if (!file) return;
            this.imagePreviewUrl = URL.createObjectURL(file);
            const reader = new FileReader();
            reader.onload = () => {
                const base64Image = reader.result;
                this.comprovanteBase64 = reader.result;
            };
        },
        checkDadosVenda() {
            if (this.dadosCupom.razao_social == "---" || this.dadosCupom.CNPJ == "---" ||
                this.dadosCupom.itens_compra == "---" || this.dadosCupom.informacoes_compra == "---" || this.dadosCupom.informacoes_compra.valor_total == "---") {
                this.$snotify.error("Falha na leitura dos dados do cupom, por favor inserir os dados no formulário");
                // this.isValid = false;
                return;
            }
            else {
                const tipoPagamentoExiste = this.tipoPagamento.find(tp => tp.text.toUpperCase() === this.dadosCupom.informacoes_compra.forma_pagamento.toUpperCase());
                if (tipoPagamentoExiste !== null) {
                    this.venda.meioPagamento = tipoPagamentoExiste;
                }
                else {
                    this.$snotify.error("Falha na leitura dos dados do cupom, por favor inserir os dados no formulário");
                    // this.isValid = false;
                    return;
                }

                this.venda.valor.value = this.dadosCupom.informacoes_compra.valor_total;
                this.venda.dataVenda.value = this.dadosCupom.dataCompra;
                this.venda.descricao = this.dadosCupom.informacoes_compra[0].descricao;
            }
        },

        async submit() {
            this.$loading(true);

            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const newVenda = {};
            Object.keys(this.venda).forEach((m) => {
                if (this.venda[m].value)
                    newVenda[m] = this.venda[m].value;
            });
            newVenda.valor =
                parseFloat(
                    this.venda.valor.value
                        .replace("R$", "")
                        .replace(".", "")
                        .replace(",", ".")
                );

            let objectToQrCode = {
                idComerciante: user.id,
                descricao: newVenda.descricao
                    .normalize("NFD")
                    .replace(/[^a-z\d\s\.,]+/gi, "")
                    .toString(),
                valor: newVenda.valor,
                nomeComerciante: user.username
                    .normalize("NFD")
                    .replace(/[^a-z\d\s\.,]+/gi, "")
                    .toString(),
                id: uuidv4()
            };

            let buffer = new Buffer(JSON.stringify(objectToQrCode));
            newVenda.token = buffer.toString("base64");
            newVenda.comprovanteCompra = this.comprovanteBase64;
            newVenda.compraUsuario = true;
            newVenda.idComerciante = this.venda.idComerciante;
            newVenda.urlChaveDeAcessoNF = this.textoQRCode;

            await http.post("/venda/criarVenda", newVenda)
                .then(data => {
                    if (data.status === 201) {
                        this.$snotify.success("Cupom fiscal registrado com sucesso!");

                        setTimeout(() => {
                            location.reload();
                        }, 2000);
                    }
                    else
                        this.$snotify.error("Ocorreu um erro ao inserir cupom fiscal!");
                })
                .catch((error) => {
                    this.handleApiError(error);
                })
                .finally(() => {
                    this.$loading(false);
                });
        },
        openModal() {
            this.$bvModal.show("modal-confirmacao");
        },
        closeModal() {
            this.$bvModal.hide("modal-center");
            this.$bvModal.hide("modal-information");
            this.tabIndex = 0;
        },
        closeModalConfirmacao() {
            this.$bvModal.hide("modal-confirmacao");
            window.location.reload();
        },
        handleApiError(error) {
            if (error.response) {
                let allErrorMessages = "";

                error.response.data.erros.forEach(function (error) {
                    allErrorMessages += error.mensagem + "\n";
                });

                allErrorMessages = allErrorMessages.slice(0, -1);

                this.$snotify.error(allErrorMessages);
                return true;
            } else if (error.request) {
                this.$snotify.error("Erro ao se comunicar com o servidor. Por favor, verifique sua conexão de internet e tente novamente.");
            } else {
                this.$snotify.error("Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.");
            }
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.previewImageFile = e.target.result;
                    this.comprovanteBase64 = reader.result;
                };
                reader.readAsDataURL(file);
            }
        },
        async getComprasAguardandoAprovacao() {
            this.$loading(true);

            await http.get(`/compra/compras-aguardando-confirmacao`)
                .then((response) => {
                    this.comprasAguardandoAprovacao = response.data.data;
                })
                .finally(() => this.$loading(false));
        },

        handleInput(value) {
            this.apiErrorMessage = "";
            if (value === 10) {
              this.verificarSaldo();
            }
        },

        async verificarSaldo(){
            this.$loading(true);
            var venda = {};
            venda.valorCompra = parseFloat(this.venda.valor.value.replace('R$','').replace('.','').replace(',','.'));

            await http.post("/user/verificarSaldo", venda)
                .then(response => {
                    this.isDisabled = false;
                    this.valorMinimo = response.data.valorMinimo;
                    this.valorTarifa = response.data.valorTarifa;
                    if (!response.data.saldoSuficiente) {
                        this.isDisabled = true;
                        this.apiError = true;
                        this.apiErrorMessage = response.data.mensagens[0];    
                    }                    
                }).finally(() => {
                    this.$loading(false);
                })
        },

        limparDados(){
            this.venda.valor = { value: 0, clicked: false };
            this.venda.percentualCashback = { value: 0, clicked: false };
            this.venda.documento = { value: "", clicked: false };
            this.venda.dataVenda = { value: new Date(), clicked: false };
            this.venda.descricao = { value: "", clicked: false };
            this.venda.meioPagamento = {
                value: 0,
                text: "Selecione o meio de pagamento",
                clicked: false
            };
            this.venda.compraUsuario = true;
            this.venda.compraUsuarioAprovada = false;
            this.venda.comprovanteCompra = "";
            this.venda.idComerciante = null;
            this.venda.chaveAcesso = { value: "", clicked: false };
            this.venda.estabelecimento = null;
            this.estabelecimentoSelecionado.estabelecimento = null;
            this.estabelecimentos = null;
        }
    },
};
