<template>
  <div class="container-fluid">
    <!-- Tabela de totais por dia -->
    <div class="card shadow rounded-0">
      <div
        class="card-header rounded-0"
        style="background-color: #388695; color: #fff"
      >
        <h5 class="text-uppercase mb-0">Cadastros por dia</h5>
      </div>
      <div class="card-body">
        <!-- Filtros -->
        <div class="card rounded-0 border-0 mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <select v-model="filtro.mes" class="form-control">
                    <option
                      v-for="mes in meses"
                      :key="mes.valor"
                      :value="mes.valor"
                    >
                      {{ mes.texto }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <select v-model="filtro.ano" class="form-control">
                    <option v-for="ano in anos" :key="ano" :value="ano">
                      {{ ano }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <button @click="buscarCadastros" class="btn btn-primary">
                  <font-awesome-icon
                    v-if="!isLoading"
                    icon="search"
                    class="mr-1"
                  />
                  <font-awesome-icon v-else icon="spinner" spin class="mr-1" />
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-hover table-sm table-striped">
            <thead>
              <tr>
                <th class="text-center" style="color: #388695">Data</th>
                <th class="text-center" style="color: #388695">
                  Total de cadastros
                </th>
                <th class="text-center" style="color: #388695"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="cadastrosPorDia.length === 0">
                <td colspan="3" class="text-center">Nenhum registro encontrado</td>
              </tr>
              <tr v-for="item in cadastrosPorDia" :key="item.data">
                <td class="text-center">{{ item.DataCadastroFormatada }}</td>
                <td class="text-center">{{ item.Quantidade }}</td>
                <td class="text-center">
                  <b-button
                    size="sm"
                    variant="info"
                    style="color: #fff"
                    @click="verDetalhesDia(item.DataCadastro)"
                  >
                    Ver detalhes
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">...</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal com detalhes do dia -->
    <b-modal
      id="modalDetalhes"
      size="xl"
      :title="`Cadastros do dia ${formatarData(diaDetalheSelecionado)}`"
      hide-footer
    >
      <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
        <div
          class="col-12 col-md-4 col-xl-4"
          v-for="cadastro in cadastrosDoDia"
          :key="cadastro.Login"
        >
          <div class="card h-100 border-0 shadow-sm">
            <div class="card-header bg-transparent border-bottom-0">
              <div class="d-flex flex-column gap-2">
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-muted">Nome:</span>
                  <h6 class="mb-0 text-primary">{{ cadastro.Nome }}</h6>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-muted">Login:</span>
                  <span>{{ cadastro.Login }}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-muted">Email:</span>
                  <span>{{ cadastro.Email }}</span>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex flex-column gap-2">
                <div class="d-flex justify-content-between">
                  <span class="text-muted">Telefone:</span>
                  <span>{{ formatarTelefone(cadastro.Celular) }}</span>
                </div>
                <div class="d-flex justify-content-between">
                  <span class="text-muted">Patrocinador:</span>
                  <span>{{ cadastro.Patrocinador }}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-muted">Email confirmado:</span>
                  <font-awesome-icon
                    :icon="cadastro.EmailConfirmado ? 'check' : 'times'"
                    :class="
                      cadastro.EmailConfirmado ? 'text-success' : 'text-danger'
                    "
                  />
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-muted">Indicado pelo QS:</span>
                  <font-awesome-icon
                    :icon="cadastro.IndicadoPeloQS ? 'check' : 'times'"
                    :class="
                      cadastro.IndicadoPeloQS ? 'text-success' : 'text-danger'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import apiServices from "@/_helper/api-services";

export default {
  name: "CadastrosPorDia",

  data() {
    return {
      filtro: {
        mes: new Date().getMonth() + 1,
        ano: new Date().getFullYear(),
      },
      meses: [
        { valor: 1, texto: "Janeiro" },
        { valor: 2, texto: "Fevereiro" },
        { valor: 3, texto: "Março" },
        { valor: 4, texto: "Abril" },
        { valor: 5, texto: "Maio" },
        { valor: 6, texto: "Junho" },
        { valor: 7, texto: "Julho" },
        { valor: 8, texto: "Agosto" },
        { valor: 9, texto: "Setembro" },
        { valor: 10, texto: "Outubro" },
        { valor: 11, texto: "Novembro" },
        { valor: 12, texto: "Dezembro" },
      ],
      anos: [],
      cadastrosPorDia: [],
      cadastrosDoDia: [],
      diaDetalheSelecionado: null,
      isLoading: false,
    };
  },

  created() {
    // Gera lista de anos (atual - 5 até atual + 1)
    const anoAtual = new Date().getFullYear();
    for (let i = anoAtual - 5; i <= anoAtual + 1; i++) {
      this.anos.push(i);
    }

    this.buscarCadastros();
  },

  methods: {
    async buscarCadastros() {
      this.isLoading = true;
      try {
        const response = await apiServices.get(
          "/Admin/obter-total-cadastros-por-dia",
          {
            mes: this.filtro.mes,
            ano: this.filtro.ano,
          }
        );
        this.cadastrosPorDia = response.data;
      } catch (error) {
        console.error("Erro ao buscar cadastros:", error);
        const mensagens = apiServices.mensagensErro(
          error,
          "Erro ao buscar cadastros"
        );
        alert(mensagens.join("\n"));
      } finally {
        this.isLoading = false;
      }
    },

    async verDetalhesDia(data) {
      try {
        this.diaDetalheSelecionado = data;
        const response = await apiServices.get(
          "/Admin/obter-cadastros-por-dia",
          {
            dia: data,
          }
        );
        this.cadastrosDoDia = response.data;
        this.$bvModal.show("modalDetalhes");
      } catch (error) {
        console.error("Erro ao buscar detalhes:", error);
        const mensagens = apiServices.mensagensErro(
          error,
          "Erro ao buscar detalhes do dia"
        );
        alert(mensagens.join("\n"));
      }
    },

    formatarCPF(cpf) {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    },

    formatarTelefone(telefone) {
      return telefone.replace(/(\d{2})(\d{4,5})(\d{4})/g, "($1) $2-$3");
    },

    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },

    formatarDataHora(data) {
      return moment(data).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped>
.table-sm td,
.table-sm th {
  padding: 0.5rem;
}

.fas.fa-check {
  color: #dc3545;
}

.fas.fa-times {
  color: #28a745;
}
</style>
