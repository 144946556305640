import './DadosPessoais.scss'
import http from "@/_helper/api-services.js";

export default {
    name: 'DadosPessoais',
    props: ['dadosPessoais', 'erroForm', 'documentoPreenchido'],
    data() {
        return {
            estados: [{ text: "Selecione o estado", value: null }],
            generos: [{ text: 'Selecione o gênero', value: null }, 'Masculino', 'Feminino', 'Prefiro não dizer'],
        }
    },
    mounted() {
        this.buscarEstados();
    },
    methods: {
        handleSubmit(event) {
            event.preventDefault();
            this.$emit("submit", "dados-pessoais");
        },
        buscarEstados() {
            this.$loading(true);
            http.get('/Geral/buscaEstado').then((response) => {
                response.data.forEach(element => {
                    this.estados.push({
                        value: element.uf,
                        text: element.nome
                    })

                    this.$loading(false);
                });
            });
        },
        ChecaForm()
        {
            if (!this.dadosPessoais.celular ||
                this.removeNonNumeric(this.dadosPessoais.celular).length < 10) {
                this.$snotify.error("Informe o telefone.");
                return false;
            }
        },
        removeNonNumeric(text) {
            if (!text)
                return "";

            return text.match(/\d+/g).join("");
        },    
    }
}