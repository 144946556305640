import Axios from "axios";
const URL = process.env.VUE_APP_ROOT_API
  ? process.env.VUE_APP_ROOT_API
  : `${location.origin}/api`;

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.headers &&
      error.response.headers["token-expired"] == "true"
    ) {
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        const response = await Axios.post(
          URL + "/UsuarioLogin/RefreshToken",
          {
            token: user.token,
            refreshToken: user.refreshToken,
          }
        );

        user.token = response.data.token;
        user.refreshToken = response.data.refreshToken;

        localStorage.setItem("user", JSON.stringify(user));
        window.location.reload();
      } catch (err) {
      }
    } else if (error.response && error.response.status == 403) {
      window.location.href = "/no-permission";
    } else if (error.response && error.response.status == 401) {
      localStorage.removeItem("user");
      if (window.location.pathname != "/") {
        window.location.href = "/";
      }
    }
    throw error;
  }
);

export default {
  login(requestURL, data) {
    return Axios.post(URL + requestURL, data);
  },

  get(requestURL) {
    let authHeader = {
      method: "GET",
      headers: this.authHeader(),
    };
    return Axios.get(URL + requestURL, authHeader);
  },

  get(requestURL, params, headers = null) {
    let authHeader = {
      method: "GET",
      headers: headers != null ? headers : this.authHeader(),
      params: params
    };
    return Axios.get(URL + requestURL, authHeader);
  },

  post(requestURL, data) {
    let authHeader = {
      method: "POST",
      headers: this.authHeader(),
    };
    return Axios.post(URL + requestURL, data, authHeader);
  },

  update(requestURL, data) {
    let authHeader = {
      method: "PUT",
      headers: this.authHeader(),
    };
    return Axios.put(URL + requestURL, data, authHeader);
  },

  patch(requestURL, data) {
    let authHeader = {
      method: "PATCH",
      headers: this.authHeader(),
    };
    return Axios.patch(URL + requestURL, data, authHeader);
  },

  delete(requestURL) {
    let authHeader = {
      method: "DELETE",
      headers: this.authHeader(),
    };
    return Axios.delete(URL + requestURL, authHeader);
  },

  authHeader() {
    let USER = JSON.parse(localStorage.getItem("user"));
    if (USER && USER.token) {
      return { Authorization: "Bearer " + USER.token };
    } else {
      return {};
    }
  },

  mensagensErro(response, mensagemPadrao = "Ocorreu um erro desconhecido.") {
    let erros = [mensagemPadrao];
    if (
      response &&
      response.data &&
      response.data.erros &&
      response.data.erros.length > 0
    ) {
      erros = response.data.erros.map((m) => m.mensagem);
    } else if (
      response.isAxiosError &&
      response.response &&
      response.response.data &&
      response.response.data.erros &&
      response.response.data.erros.length > 0
    ) {
      erros = response.response.data.erros.map((m) => m.mensagem);
    }
    return erros;
  },
};
