<template>
  <div class="container bg-white px-3 background-image">
    <!-- <InfoVideo
      title="Meta Minuto"
      video-url="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
    /> -->

    <div class="row">
      <div
        class="col-12 d-flex flex-row align-items-center justify-content-center"
      >
        <img src="@/assets/imgs/meta-minuto.png" alt="Meta Minuto" />
        <h5 class="font-weight-bold display-md-4 text-green mb-0 ml-3">
          Meta Minuto
        </h5>
      </div>
    </div>

    <div class="row mb-0">
      <div class="col-12">
        <div v-if="!completed" class="d-flex flex-row">
          <div class="align-items-center col-4 d-flex justify-content-start">
            <h4 class="countdown-time">{{ countdownTime }}</h4>
          </div>
          <div class="align-items-center col-8 d-flex justify-content-end">
            <h4 class="target-value">{{ targetValue | formatCurrency }}</h4>
          </div>
        </div>
        <div v-if="completed">
          <b-alert fade show variant="success" class="mr-2 text-center">
            Parabéns, você já atingiu sua méta diária, continue assim!!
          </b-alert>
        </div>

        <div v-if="!completed">
          <b-progress
            :value="progressValue"
            :max="100"
            height="0.5rem"
          ></b-progress>

          <div>
            <span
              class="text-muted d-block text-right mt-1"
              style="font-size: 0.775rem"
            >
              Sua meta
              {{
                timeSelected == 1
                  ? "diária"
                  : timeSelected == 2
                  ? "semanal"
                  : "mensal"
              }}
              é de
              {{
                timeSelected == 1
                  ? 14.4
                  : timeSelected == 2
                  ? 100.8
                  : 432.0 | formatCurrency
              }}
            </span>
          </div>

          <!-- Botões de tempo -->
          <div class="col-12 d-flex justify-content-center">
            <div class="d-flex justify-content-end">
              <span
                class="green-button-2 meta-minuto-button"
                @click="timeSelected = 1"
                :class="{ 'active-button': timeSelected == 1 }"
                style="font-size: 0.625rem;"
              >
                Diário
              </span>

              <span
                class="green-button-2 meta-minuto-button"
                @click="timeSelected = 2"
                :class="{ 'active-button': timeSelected === 2 }"
                style="font-size: 0.625rem;"
              >
                Semanal
              </span>

              <span
                class="green-button-2 meta-minuto-button"
                @click="timeSelected = 3"
                :class="{ 'active-button': timeSelected === 3 }"
                style="font-size: 0.625rem;"
              >
                Mensal
              </span>
            </div>
          </div>
          <!-- Fim dos botões de tempo -->

          <p class="font-weight-bold text-left mb-0 mt-2">
            Quanto você precisa acumular por minuto para alcançar seu objetivo
            mensal?
          </p>
          <p class="text-justify mb-0 mt-1">
            Meta Minuto é simples: Por exemplo, acumulando 1 centavo por minuto,
            você pode atingir R$ 432 ao final do mês. Acima você pode acompanhar
            quanto você precisa gerar por minuto diário, semanal e mensal.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoVideo from "./InfoVideo.vue";
import moment from "moment";
import http from "@/_helper/api-services.js";
export default {
  components: {
    InfoVideo,
  },
  data() {
    return {
      initialMinutes: 1,
      countdownTime: "",
      targetValue: 0.01,
      targetValueAux: 0.01,
      progressValue: 0,
      valorDia: 0,
      valorcashback: 0,
      timeSelected: 1,
      completed: false,
    };
  },
  created() {
    // this.setInitialMinutes();
  },
  async beforeMount() {
    await this.buscarCashbackMetaMinuto();
    this.validaValor();
  },
  watch: {
    timeSelected(newVal, oldVal) {
      this.buscarCashbackMetaMinuto();
    },
  },
  mounted() {
    this.startCountdown(this.initialMinutes);
  },
  methods: {
    formatTime(minutes, seconds) {
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
        2,
        "0"
      )}`;
    },
    startCountdown(minutes) {
      let totalSeconds = minutes * 60 - moment().seconds();
      const totalDuration = totalSeconds;

      const intervalId = setInterval(() => {
        const remainingMinutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;

        this.countdownTime = this.formatTime(
          remainingMinutes,
          remainingSeconds
        );

        const progress = ((totalDuration - totalSeconds) / totalDuration) * 100;
        this.progressValue = progress;

        totalSeconds--;

        if (totalSeconds < 0) {
          clearInterval(intervalId);
          this.countdownTime = "00:00";
          this.progressValue = 100;

          if (this.targetValueAux >= this.targetValue) {
            this.targetValue += 0.01;
          }

          this.targetValueAux += 0.01;
          this.startCountdown(this.initialMinutes);
        }
      }, 1000);
    },
    validaValor() {
      this.targetValueAux = this.targetValueAux - this.valorcashback;
      this.targetValue = this.targetValue - this.valorcashback;

      if (this.targetValue - this.valorcashback <= 0) {
        this.targetValue = 0.0;
      }
    },
    async buscarCashbackMetaMinuto() {
      this.$loading(true);

      let dataInicio;
      let dataFim;

      switch (this.timeSelected) {
        case 1:
          this.targetValue = 0.01;
          this.targetValueAux = 0.01;
          this.valorDia = moment().diff(moment().startOf("day"), "minutes");
          this.targetValue = this.valorDia * this.targetValue;
          this.targetValueAux = this.valorDia * this.targetValueAux;
          dataInicio = moment().format("YYYY-MM-DD");
          dataFim = moment().format("YYYY-MM-DD");
          this.validaValor();
          this.completed = this.targetValue < 14.4 ? false : true;
          break;
        case 2:
          this.targetValue = 0.01;
          this.targetValueAux = 0.01;
          this.valorSemana = moment().diff(moment().startOf("week"), "minutes");
          this.targetValue = this.valorSemana * this.targetValue;
          this.targetValueAux = this.valorSemana * this.targetValueAux;
          dataInicio = moment().startOf("week").format("YYYY-MM-DD");
          dataFim = moment().format("YYYY-MM-DD");
          this.validaValor();
          this.completed = this.targetValue < 100.8 ? false : true;
          break;
        case 3:
          this.targetValue = 0.01;
          this.targetValueAux = 0.01;
          this.valorMes = moment().diff(moment().startOf("month"), "minutes");
          this.targetValue = this.valorMes * this.targetValue;
          this.targetValueAux = this.valorMes * this.targetValueAux;

          dataInicio = moment().startOf("month").format("YYYY-MM-DD");
          dataFim = moment().format("YYYY-MM-DD");
          this.validaValor();
          this.completed = this.targetValue < 432.0 ? false : true;
          break;
        default:
          this.targetValue = 0.01;
          this.targetValueAux = 0.01;
          dataInicio = moment().format("YYYY-MM-DD");
          dataFim = moment().format("YYYY-MM-DD");
          this.validaValor();
          break;
      }

      await http
        .get(`/v2/dashboard/get-user-month-cashback/${dataInicio}/${dataFim}`)
        .then(
          (success) => {
            this.valorcashback = success.data.data;
            this.$loading(false);
          },
          () => {
            this.$snotify.error("Erro ao buscar o cashback do usuário");
            this.$loading(false);
          }
        );
      this.$loading(false);
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container {
  font-family: "Poppins", sans-serif !important;
}

.meta-minuto-button {
  background-color: #8a9ba8 !important;
  color: white !important;
  border: 0;
  border-radius: 0;
  font-family: "Poppins", sans-serif !important;
  font-size: 0.5rem;
  font-weight: 500;
  text-align: center;
  width: 6.1rem;
  cursor: pointer;
  white-space: nowrap
}

.active-button {
  background-color: #98c73a !important;
  opacity: 1;
}

.meta-minuto-button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.meta-minuto-button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

@media (max-width: 412px) {
  h4 {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  h4 {
    font-size: 2.2rem;
  }
}

.countdown-time {
  color: #1e5d68;
  font-weight: bold;
  margin: 0 !important;
  font-size: 1.5rem;
}

.target-value {
  color: #1e5d68;
  margin: 0 !important;
  font-size: 1.5rem;
}

.background-image {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.background-image::before {
  content: "";
  position: absolute;
  top: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/imgs/relogio-meta-minuto.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  opacity: 0.15;
  transform: rotate(-20deg);
  transform-origin: center;
  pointer-events: none;
}

div > p {
  font-size: 0.875rem;
}

.title-color {
  color: #98c73a !important;
}
</style>
